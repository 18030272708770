import React, { useState, useEffect } from 'react';
import ContactInfoRow from './ContactInfoRow';
import ModalContactInfo from './ModalContactInfo';
import Spinner from './Spinner';
import '../scss/ContactInfoCompact.scss';
import {useApi} from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from './PermissionsGate';
import { HiPhone, HiOutlinePlus } from 'react-icons/hi';
import intl from 'react-intl-universal';

const ContactInfoCompact = ({ contactInfosId, editScopes, deleteScopes, setContactInfosId, disabled }) => {

    const api: Api = useApi();

    const [contactInfos, setContactInfos] = useState([]);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const [isContactInfoDetailOpen, setIsContactInfoDetailOpen] = useState(false);

    const fetchContactInfo = async (contactInfoIds: number[]) => {
        setLoading(true);
        const response = await api.contactInfoApi.apiVversionContactInfoContactinfosGet("1", contactInfoIds, {}).then((response) => {
            if(response.data) {
                setContactInfos(response.data?.data);
                setLoading(false);
                console.log("Success");
            };
        }).catch((error) => {
            if(error.response) {
                console.log("Data :" , error.response.data);
                console.log("Status :" + error.response.status);
            } else if(error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('contactInfo.toast.error.fetchContactInfo'), body: error.message });
            setLoading(false);
        });
    };

    useEffect(() => {
        if (contactInfosId.length > 0) {
            fetchContactInfo(contactInfosId);
        };
    }, [contactInfosId]);

    const closeContactInfoDetail = () => setIsContactInfoDetailOpen(false);

    const handleContactInfoDetailOpen = () => {
        setIsContactInfoDetailOpen(true);
    };
    
    return (
        <>
            <div className="card-header">
                <h4 className="title"><HiPhone className='mb-1'/> {intl.get('contactInfoCompact.header')}</h4>
            </div>
            {
                loading === true ?
                <Spinner small={true} /> :
                <div className='card-body'>
                    <div className="table-responsive container-overflow">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" >{intl.get('contactInfoCompact.table.thead.name')}</th>
                                    <th scope="col" >{intl.get('contactInfoCompact.table.thead.email')}</th>
                                    <th scope="col" >{intl.get('contactInfoCompact.table.thead.phone1')}</th>
                                    <th scope="col" >{intl.get('contactInfoCompact.table.thead.phone2')}</th>
                                    <th scope="col" >{intl.get('contactInfoCompact.table.thead.role')}</th>
                                    <th scope="col" className='text-center'>{intl.get('contactInfoCompact.table.thead.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    contactInfos?.map((e,i) => (
                                        <ContactInfoRow 
                                        key={e.id}
                                        contactInfoId={e.id}
                                        contactName={e.contactName}
                                        emailAddress={e.emailAddress}
                                        phone1={e.phone1}
                                        phone2={e.phone2}
                                        role={e.role}
                                        editScopes={editScopes}
                                        deleteScopes={deleteScopes}
                                        disabled={disabled}
                                        contactInfos={contactInfos}
                                        setContactInfos={setContactInfos}
                                        className={i%2>0?'':'odd'}
                                        />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >  
                    <div className="text-center mt-2">
                        <button type="button" className="btn btn-primary newContact" disabled={disabled} onClick={handleContactInfoDetailOpen}><HiOutlinePlus className='mb-1'/> {intl.get('contactInfoCompact.newContact.button')}</button>
                        <ModalContactInfo isOpen={isContactInfoDetailOpen} closeModal={closeContactInfoDetail} contactInfoId={null} contactInfos={contactInfos} setContactInfos={setContactInfos} contactInfosId={contactInfosId} setContactInfosId={setContactInfosId} loading={loading} setLoading={setLoading} />
                    </div>
                    </PermissionsGate>
                </div>
            }
        </>
    )
}

export default ContactInfoCompact;