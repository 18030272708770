import React, { useState, useEffect, useContext } from 'react'
import {parse} from 'url';
import * as cognito from '../../common/cognito'
import {  CognitoIdToken, CognitoAccessToken} from 'amazon-cognito-identity-js';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import {MIN_BEFORE_EXPIRATION} from '../../../common/constants';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';
import { useNavigate } from "react-router-dom";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  signIn?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.SignedOut,
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const handleImplicitFlow = (currentUrl: string) => {
  // hash is `null` if `#` doesn't exist on URL
  const { id_token, access_token } = (parse(currentUrl).hash || '#')
      .substr(1) // Remove # from returned code
      .split('&')
      .map(pairings => pairings.split('='))
      .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {
          id_token: undefined,
          access_token: undefined,
      });

  if(!access_token )
    return null;
  return {
      accessToken: access_token,
      idToken: id_token,
      refreshToken: null,
  };
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.SignedOut)
  const [sessionInfo, setSessionInfo] = useState({})
  const toast = useToastMessageQueue();
  var retries = 0;
  useEffect(() => {
    async function getSessionInfo() {
      let tokens:any;  
      try {
        if(!!sessionStorage.getItem('authCode') && authStatus!=AuthStatus.SignedIn && retries <=3)
        {
            //authorization code received, retrieve tokens
            cognito.getTokens(sessionStorage.getItem('authCode')).then((resp)=>
            {
                sessionStorage.removeItem('authCode');
                tokens = resp.data;
                      
                sessionStorage.setItem('accessToken', `${tokens.access_token}`)
                sessionStorage.setItem('idToken', `${tokens.id_token}`)
                sessionStorage.setItem('refreshToken', `${tokens.refresh_token}`)
                sessionStorage.setItem('tokenExpires', `${tokens.expires_in}`)
                setSessionInfo({
                  idToken: tokens.id_token,
                  accessToken: tokens.access_token,
                  refreshToken: tokens.refresh_token,
                })
                const AccessToken = new
                CognitoAccessToken({ 
                    AccessToken: tokens.accessToken,
                });
                const IdToken = new
                CognitoIdToken({
                    IdToken: tokens.id_token,
                });
                sessionStorage.setItem('username', IdToken.payload.email);
                
                setAuthStatus(AuthStatus.SignedIn);
                retries = 0;
            })
            .catch((err)=>{
              console.log(err);
              toast.error({ header: 'Error', body:  intl.get('login.tokens.failed')});
              retries++;
            });
        }
        else if(authStatus===AuthStatus.SignedIn || sessionStorage.getItem('accessToken')!=null)
        {
          tokens = await getSession();
          if(!!tokens && !!tokens.accessToken)
          {
              console.debug("Getting tokens from session")
              setAuthStatus(AuthStatus.SignedIn)
              sessionStorage.setItem("expiration_handle_id", setToHappen(()=>{ handleSessionEnding()}, parseJwt(tokens.accessToken).exp - MIN_BEFORE_EXPIRATION).toString());
          }
        }
      } catch (err) {
        console.log(err);
        toast.error({ header: 'Error', body:  intl.get('login.userinfo.failed')});
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return children;
  }

  function setToHappen(fn, date): number {
    console.debug(`Setting ${fn.toString()} to happen in ${date * 1000 - Date.now()} ms. `);
    return window.setTimeout(fn, date * 1000 - Date.now());
  } 

  const handleSessionEnding = () => {
    const timeoutId =  setTimeout(()=>{signOut()
      ;window.location.href="/";}, 60000 );
    confirmAlert({
      title: intl.get('login.expiring.title'),
      message: intl.get('login.expiring.message'),
      buttons: [
          {
              label: intl.get('login.expiring.button.continue'),
                onClick: async () => {
                  cognito.refreshTokens(sessionStorage.getItem('refreshToken')).then((resp)=>
                  {
                      let tokens = JSON.parse(resp.data.result);   
                      sessionStorage.setItem('accessToken', `${tokens.access_token}`)
                      sessionStorage.setItem('idToken', `${tokens.id_token}`)
                      sessionStorage.setItem('tokenExpires', `${tokens.expires_in}`)
                      setSessionInfo({
                        idToken: tokens.id_token,
                        accessToken: tokens.access_token,
                      })
                      setAuthStatus(AuthStatus.SignedIn);
                      clearTimeout(timeoutId);
                      sessionStorage.setItem("expiration_handle_id", setToHappen(()=>{ handleSessionEnding()}, parseJwt(tokens.access_token).exp - MIN_BEFORE_EXPIRATION).toString());

                  })
                  .catch((err)=>{
                    toast.error({ header: 'Error', body:  intl.get('login.tokens.failed')});
                    signOut();
                    window.location.href="/";
                  });
                },
            }
        ]
    });
};

  function signIn(){
    console.debug("Signing in");
    cognito.signIn();
  }

  function signOut() {
    console.debug("Signing out");
    setAuthStatus(AuthStatus.SignedOut);
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem("expiration_handle_id");
    sessionStorage.removeItem('UserEntity');
  }

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  async function getSession() {
    console.debug("Getting session data");
    try {
      const session = {
        idToken: sessionStorage.getItem("idToken"),
        accessToken: sessionStorage.getItem("accessToken"),
        refreshToken: sessionStorage.getItem("refreshToken")
      }
      var tokens;
      if (!session.idToken && !session.accessToken){
        // moved to authorization code handleImplicitFlow(window.location.href);
      } else { 
        tokens = session;
      }
      return tokens;
    } catch (err) {
      throw err
    }
  }
  const state: IAuth = {
    authStatus,
    sessionInfo,
    signIn,
    signOut,
  }
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}
     export default AuthProvider
