import React from 'react';
import intl from 'react-intl-universal';
import '../scss/RequiredField.scss';

const RequiredField = () => {

    return(
        <div>
            <span className='required d-flex justify-content-start'>{intl.get('RequiredField')}</span>
        </div>
    )
}

export default RequiredField;