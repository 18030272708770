import { createContext, useContext } from 'react';
import Api from '../axiosApi/api';
const ApiContext = createContext();

export default function ApiProvider({ children }) {

  const api = new Api();
  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  return useContext(ApiContext);
}