import React from "react";
import TaskRow from "./TaskRow";
import { Task } from "axiosApi/models";
import intl from 'react-intl-universal';

export type TaskListProps = {
    tasksByJob: Task[],
    handleTaskEdit: Function,
    handleTaskDelete: Function,
    isTaskEditMode: Function
}
const TaskList = ({ tasksByJob,  handleTaskEdit, handleTaskDelete, isTaskEditMode }: TaskListProps) => {

    return (
        <div className="table-responsive container-overflow">
            <table className="table table-hover ">
                <thead>
                    <tr>
                        <th scope="col">{intl.get('taskList.table.thead.task')}</th>
                        <th scope="col">{intl.get('taskList.table.thead.jira')}</th>
                        <th scope="col" style={{ textAlign: 'center' }}>{intl.get('taskList.table.thead.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasksByJob && tasksByJob.map((task) => (
                            <TaskRow
                                key={task.id}
                                idTask={task.id}
                                description={task.description}
                                idJira={task.idJira}
                                handleTaskEdit={handleTaskEdit}
                                handleTaskDelete={handleTaskDelete}
                                isTaskEditMode={isTaskEditMode}
                            />
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default TaskList;