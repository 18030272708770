import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';

type RequestStatusSelectProps = {
  requestStatus: any,
  selectedStatus: any,
  handleStatusChange: any,
  isClearable: boolean
};

const RequestStatusSelect: FC<RequestStatusSelectProps> = ({ requestStatus, selectedStatus, handleStatusChange, isClearable }) => {
  
  return (
    <>
      <Select
        id='requestStatus'
        name='requestStatus'
        getOptionLabel={e => e.name}
        getOptionValue={e => e.id}
        placeholder={intl.get('requestStatusSelect.placeholder')}
        value={(requestStatus != null && selectedStatus != null) ? (requestStatus.find((status) => status.id === selectedStatus)) : ''}
        onChange={handleStatusChange}
        isLoading={requestStatus == null}
        options={requestStatus}
        isClearable={isClearable}
      />
    </>
  );
}

export default RequestStatusSelect;