import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import RequestSelect from './RequestSelect';
import DateRangePickerDialog from 'components/DateRangePickerDialog';
import intl from 'react-intl-universal';
import '../../scss/Time Off/TimeOffRequest.scss';
import StyledDropzone from "./TimeOffFileUploader";
import Spinner from '../Spinner';

const TimeOffRequestForm = ({ requestType, input, setInput, errors, handleCancel, loadingSave, handleRequestChange, handleDaySelect, handleSave }) => {
    
    return (
        <div className='container'>
            <Row className='justify-content-around'>
                <Col md={12}>
                    <div>
                        <Label for="TimeOffRequestType" className={`${errors.idTimeOffRequestType ? 'text-danger' : ''}`}>
                            {intl.get('timeOff.type')}
                        </Label>
                        <RequestSelect requestType={requestType} selectedRequest={input.idTimeOffRequestType} handleRequestChange={handleRequestChange} />
                    </div>
                    <div className='mt-20'>
                        <Label for="TimeOffRequestType" className={`${errors.range ? 'text-danger' : ''}`}>
                            {intl.get('timeOff.time.range')}
                        </Label>
                        <DateRangePickerDialog
                            disabled={false}
                            inputName="dateRange"
                            className='timesheet-datepicker'
                            selected={input.range}
                            setRange={handleDaySelect}
                            required
                        />
                    </div>
                    <div className='mt-20'>
                        <StyledDropzone
                            files={input.attachments}
                            setInput={setInput}
                        />
                    </div>
                    <div className='mt-20'>
                    <Row className='justify-content-between'>
                        <Col md={6}>
                            <Label for="MandatoryFields">
                                {intl.get('timeOff.mandatory.field')}
                            </Label>
                        </Col>
                        <Col md={6}>
                        {
                            loadingSave === true ?
                            <Spinner small={true} /> :
                            <div className='container-fluid request gap-3'>
                                <button className='btn btn-outline-dark p-2' type='button' id='Cancel' data-tooltip-id='tooltipButton' onClick={handleCancel}>{intl.get('timeOff.cancel')}</button>
                                <button className='btn btn-outline-dark p-2' type='button' id='SaveAsDraft' data-tooltip-id='tooltipButton'>{intl.get('timeOff.save.as.draft')}</button>
                                <button className='btn btn-primary p-2' type='button' id='SendRequest' data-tooltip-id='tooltipButton' onClick={handleSave} disabled={JSON.stringify(errors) !== JSON.stringify({}) ? true : false}>{intl.get('timeOff.send.request')}</button>
                            </div>
                        }
                        </Col>
                    </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TimeOffRequestForm;