import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';

type StatusSelectProps = {
  handleStatusChange: any,
  selectedStatus: any,
  status: any,
  disabled: boolean,
  defaultValue: any,
  isClearable: boolean
};

const StatusSelect: FC<StatusSelectProps> = ({ handleStatusChange, status, selectedStatus, disabled, defaultValue, isClearable }) => {

  let statusOptions = status.map(s => ({...s, status: s.active ? 'Active' : 'Inactive'}));

  let defaultOption = defaultValue ? statusOptions[0] : '';

  return (
    <>
      <Select
        id='status'
        name='status'
        getOptionLabel={e => e.status}
        getOptionValue={e => e.active}
        placeholder={intl.get('statusSelect.placeholder')}
        value={(statusOptions != null && selectedStatus != null) ? (statusOptions.find((status) => status.id === selectedStatus)) : defaultOption}
        onChange={handleStatusChange}
        isLoading={status == null}
        options={statusOptions}
        isDisabled={disabled}
        isClearable={isClearable}
      />
    </>
  );
}

export default StatusSelect;