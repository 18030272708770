import React, { useState, useEffect, FC, Children, useContext } from 'react';
import { UserEntityDetails } from 'axiosApi/models';
import { getUserEntity } from 'common/UserEntityProvider';
import { ENTITY_TYPE_ANY } from 'common/constants';
import { hasPermission } from './hasPermission';
import { hasRole } from './hasPermission';

type PermissionsGateProps = {
  children: any,
  RenderError?: any | null | undefined,
  viewErrorProps?: String[] ,
  editErrorProps?: String[] ,
  viewScopes: String[],
  editScopes: String[],
  viewRoles: String[] ,
  editRoles: String[],
  entityType?: number 
}

export const PermissionsGate: FC<PermissionsGateProps> =  ({
  children,
  RenderError = ()=><></>,
  viewErrorProps,
  editErrorProps,
  viewScopes,
  editScopes,
  viewRoles,
  editRoles,
  entityType=1
}) => {
  
  const [TokenRoles, setTokenRoles] = useState([]);
  const viewGranted = (hasPermission({ scopes:viewScopes }) || hasRole({ userRoles:TokenRoles, roles:viewRoles})) && (entityType == getUserEntity()?.entityType || entityType ==ENTITY_TYPE_ANY);
  const editGranted = (hasPermission({ scopes:editScopes }) || hasRole({ userRoles:TokenRoles, roles:editRoles})) && (entityType == getUserEntity()?.entityType || entityType ==ENTITY_TYPE_ANY);

  if (!viewGranted && !viewErrorProps) return <RenderError />;

  if (!viewGranted && viewErrorProps)
    return React.Children.map(children, (child => pgCloneElement(child, { ...viewErrorProps })));

  if (!editGranted && !editErrorProps){
    return React.Children.map(children, (child => pgCloneElement(child, {})));
  }
    if (!editGranted && editErrorProps)
    return React.Children.map(children, (child => pgCloneElement(child, { ...editErrorProps })));
  return <>{children}</>;
}

function pgCloneElement(child, props)
{
  console.log (child);
  switch(child.type)
  {
    case 'a':
      return <a className="disabled" onClick={(e)=>{e.preventDefault();}}>{child.props.children} </a>;
    case 'button':
        return <button className={child.props.className} disabled onClick={(e)=>{e.preventDefault();}}>{child.props.children}</button>;
    case 'td':
    case 'tr':
      return <td></td>;
    default:
      return child;
  }
}
