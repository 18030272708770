import React from "react";
import NewEmployee from './NewEmployee';
import Modal, { ModalSizes } from "../Modal";

const ModalNewEmployee = ({ isOpen, closeModal, setLoading, employees, setEmployees }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <NewEmployee closeModal={closeModal} setLoading={setLoading} employees={employees} setEmployees={setEmployees}/>
        </Modal>
    )
}

export default ModalNewEmployee;