import React, { useState, FC } from 'react';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateEmployeeCommand } from '../../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

type NewEmployeeProps = {
    closeModal: any,
    setLoading: any,
    employees: any,
    setEmployees: any,
};

const NewEmployee: FC<NewEmployeeProps> = ({ closeModal, setLoading, employees, setEmployees }) => {

    const defaultNewEmployeeInput = {
        name: "",
        lastName: "",
        emailAddress: ""
    }

    const [input, setInput] = useState(defaultNewEmployeeInput);

    const api: Api = useApi();
    
    const [disabled, setDisabled] = useState(true);

    const [errors, setErrors] = useState({name: null, lastName: null, emailAddress: null});

    const toast = useToastMessageQueue();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const validate = function (input) {
        setDisabled(false);
        let errors: any = {name: null, lastName: null, emailAddress: null};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
            setDisabled(true);
        }
        if (!input.lastName) {
            errors.lastName = intl.get('validate.errors.isRequired');
            setDisabled(true);
        }
        if (!input.emailAddress) {
            errors.emailAddress = intl.get('validate.errors.isRequired');
            setDisabled(true);
        } else if (!/\S+@\S+\.\S+/.test(input.emailAddress)) {
            errors.emailAddress = intl.get('validate.errors.isInvalid');
            setDisabled(true);
        }
        return errors;
    };

    const handleSave = async (e) => {
        setLoading(true);
        const errors = validate(input);
        setErrors(errors);
        if (errors.name === null && errors.lastName === null && errors.emailAddress === null) {
            let cmd: CreateEmployeeCommand = {
                name: input.name,
                lastName: input.lastName,
                emailAddress: input.emailAddress
            };
            const response = await api.employeeApi.apiVversionEmployeePost("1", cmd, {}).then((response) => {
                const newEmployee = response.data.data;
                addEmployee(newEmployee);
                setLoading(false);
                toast.success({ body: intl.get('employeeDetail.toast.success.handleSubmit.add')});
                closeModal();
            }).catch((error) => {
                if (error.response) {
                    console.log("Data :", error.response.data);
                    console.log("Status :" + error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toast.error({ header: intl.get('employeeDetail.toast.error.handleSubmit.add'), body: error.message });
                setLoading(false);
            });
        };
    };

    const handleCancel = () => {
        setInput(defaultNewEmployeeInput);
        setErrors({name: null, lastName: null, emailAddress: null});
        closeModal();
    };

    const addEmployee = (newEmployee) => {
        setEmployees([...employees, newEmployee])
    };

    return (
        <div className='container'>
            <div className='col'>
                <div className="card-header">
                    <h2 className='text-start mb-0'>{intl.get('newEmployee.header')}</h2>
                </div>
                <div className='card-body'>
                    <FormGroup className='mb-4'>
                        <Row form ="true" className='mb-3'>
                            <div className='d-inline-flex col-12 flex-column'>
                                <Label for="employeeName" className={`${errors.name !== "" && errors.name !== null ? 'text-danger' : ''}`}>
                                    {intl.get('employeeForm.name')} {errors.name !== "" && (<span className='text-danger'>{errors.name}</span>)}
                                </Label>
                                <Input
                                    id="employeeName"
                                    name="name"
                                    placeholder={intl.get('employeeForm.placeholder.name')}
                                    value={input?.name}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Row>
                        <Row form ="true" className='mb-3'>
                            <div className='d-inline-flex col-12 flex-column'>
                                <Label for="employeeLastName" className={`${errors.lastName !== "" && errors.lastName !== null ? 'text-danger' : ''}`}>
                                    {intl.get('employeeForm.tradeName')} {errors.lastName !== "" && (<span className='text-danger'>{errors.lastName}</span>)}
                                </Label>
                                <Input
                                    id="employeeLastName"
                                    name="lastName"
                                    placeholder={intl.get('employeeForm.placeholder.tradeName')}
                                    value={input?.lastName}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Row>
                        <Row form ="true">
                            <div className='d-inline-flex col-12 flex-column'>
                                <Label for="employeeEmail" className={`${errors.emailAddress !== "" && errors.emailAddress !== null ? 'text-danger' : ''}`}>
                                    {intl.get('employeeForm.email')} {errors.emailAddress !== "" && (<span className='text-danger'>{errors.emailAddress}</span>)}
                                </Label>
                                <Input
                                    id="employeeEmail"
                                    name="emailAddress"
                                    placeholder={intl.get('employeeForm.placeholder.email')}
                                    value={input?.emailAddress}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Row>
                    </FormGroup>
                    <Row>
                        <Col md={4} >
                            <RequiredField/>
                        </Col>
                        <Col md={8} className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button type="button" className="btn btn-outline-danger" onClick={handleCancel}>{intl.get('cancel.button')}</button>
                            <button type="button" className="btn btn-outline-success" onClick={handleSave} disabled={disabled}><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

export default NewEmployee;