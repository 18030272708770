import React, { useContext } from "react";
import { AuthContext } from './authentication/context/authContext';
import intl from 'react-intl-universal';
/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignInButton: React.FunctionComponent<{}> = () =>  {

        const authContext = useContext(AuthContext);
    
        const handleLogin = async () => {
                authContext.signIn();
        };

        return (
                <button  className="btn btn-primary btn-lg" onClick={handleLogin}>{intl.get('singInButton')}</button>
        )
}

export default SignInButton;