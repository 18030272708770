import React, { useState, useEffect, FC, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { AuthContext, AuthStatus } from 'components/authentication/context/authContext';
import { getUserEntity } from 'common/UserEntityProvider';
export const Verify: FC<null> = ({ }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const authContext = useContext(AuthContext);
        
    if(window.location.href.indexOf("verify")>0 && !!searchParams.get("code"))
    {
        sessionStorage.setItem("authCode",searchParams.get("code") );
    }
    if(authContext != null && authContext.authStatus === AuthStatus.SignedIn && getUserEntity()!=null)
    {
        sessionStorage.removeItem("authCode");
        return (<Navigate to={"/"}/>); 
    }
    return (<></>)
};