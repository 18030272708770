const addressType = {
    "addressType": [
        {
            "name": "Principal",
            "description": "Principal",
            "id": 1,
            "isDeleted": false
        },
        {
            "name": "Secondary",
            "description": "Secondary",
            "id": 2,
            "isDeleted": false
        }
    ]
}

export default addressType;