/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Jobs API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {  Settings } from "../../common/settings";
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateTaskCommand } from '../models';
import { JiraIssueResponse } from '../models';
import { JiraIssueSearchResponseResponse } from '../models';
import { StatusCodeResult } from '../models';
import { StringResponse } from '../models';
import { TaskByIdResponseResponse } from '../models';
import { TaskListResponse } from '../models';
import { TaskResponse } from '../models';
import { TaskSearchResponseResponse } from '../models';
import { UpdateTaskCommand } from '../models';
/**
 * TaskApi - axios parameter creator
 * @export
 */
const BASE_PATH = Settings.getInstance().BASE_API_URL_JOBS;
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of all Tasks
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskAllGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskAllGet.');
            }
            const localVarPath = `/api/v{version}/Task/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of Tasks by Employee Id And Project Id
         * @param {number} idEmployee 
         * @param {number} idProject 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet: async (idEmployee: number, idProject: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idEmployee' is not null or undefined
            if (idEmployee === null || idEmployee === undefined) {
                throw new RequiredError('idEmployee','Required parameter idEmployee was null or undefined when calling apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet.');
            }
            // verify required parameter 'idProject' is not null or undefined
            if (idProject === null || idProject === undefined) {
                throw new RequiredError('idProject','Required parameter idProject was null or undefined when calling apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet.');
            }
            const localVarPath = `/api/v{version}/Task/getByEmployeeAndProject/{idEmployee}/{idProject}`
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"idProject"}}`, encodeURIComponent(String(idProject)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of Tasks by Employee id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskGetByEmployeeIdIdGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionTaskGetByEmployeeIdIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskGetByEmployeeIdIdGet.');
            }
            const localVarPath = `/api/v{version}/Task/getByEmployeeId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of Tasks by Job id
         * @param {number} idJob 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskGetByJobIdJobGet: async (idJob: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idJob' is not null or undefined
            if (idJob === null || idJob === undefined) {
                throw new RequiredError('idJob','Required parameter idJob was null or undefined when calling apiVversionTaskGetByJobIdJobGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskGetByJobIdJobGet.');
            }
            const localVarPath = `/api/v{version}/Task/getByJob/{idJob}`
                .replace(`{${"idJob"}}`, encodeURIComponent(String(idJob)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a task by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskIdDelete: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionTaskIdDelete.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskIdDelete.');
            }
            const localVarPath = `/api/v{version}/Task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Task by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskIdGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionTaskIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskIdGet.');
            }
            const localVarPath = `/api/v{version}/Task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Jira Issue by Task
         * @param {number} idTask 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskJiraGetIdTaskGet: async (idTask: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTask' is not null or undefined
            if (idTask === null || idTask === undefined) {
                throw new RequiredError('idTask','Required parameter idTask was null or undefined when calling apiVversionTaskJiraGetIdTaskGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskJiraGetIdTaskGet.');
            }
            const localVarPath = `/api/v{version}/Task/jira/get/{idTask}`
                .replace(`{${"idTask"}}`, encodeURIComponent(String(idTask)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Jira Issues filtered by Project and Search term, paginated.
         * @param {string} version 
         * @param {number} [idProject] 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskJiraSearchGet: async (version: string, idProject?: number, term?: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskJiraSearchGet.');
            }
            const localVarPath = `/api/v{version}/Task/jira/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (idProject !== undefined) {
                localVarQueryParameter['idProject'] = idProject;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Task.
         * @param {string} version 
         * @param {CreateTaskCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskPost: async (version: string, body?: CreateTaskCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskPost.');
            }
            const localVarPath = `/api/v{version}/Task`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Task
         * @param {string} version 
         * @param {UpdateTaskCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskPut: async (version: string, body?: UpdateTaskCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskPut.');
            }
            const localVarPath = `/api/v{version}/Task`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all tasks by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTaskTypeaheadTextGet: async (text: string, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            if (text === null || text === undefined) {
                throw new RequiredError('text','Required parameter text was null or undefined when calling apiVversionTaskTypeaheadTextGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTaskTypeaheadTextGet.');
            }
            const localVarPath = `/api/v{version}/Task/typeahead/{text}`
                .replace(`{${"text"}}`, encodeURIComponent(String(text)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of all Tasks
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskSearchResponseResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskAllGet(version, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of Tasks by Employee Id And Project Id
         * @param {number} idEmployee 
         * @param {number} idProject 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(idEmployee: number, idProject: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskSearchResponseResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(idEmployee, idProject, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of Tasks by Employee id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskGetByEmployeeIdIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskSearchResponseResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskGetByEmployeeIdIdGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of Tasks by Job id
         * @param {number} idJob 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskGetByJobIdJobGet(idJob: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskSearchResponseResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskGetByJobIdJobGet(idJob, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a task by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StatusCodeResult>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskIdDelete(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a Task by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskByIdResponseResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskIdGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Jira Issue by Task
         * @param {number} idTask 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskJiraGetIdTaskGet(idTask: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<JiraIssueResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskJiraGetIdTaskGet(idTask, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search Jira Issues filtered by Project and Search term, paginated.
         * @param {string} version 
         * @param {number} [idProject] 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskJiraSearchGet(version: string, idProject?: number, term?: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<JiraIssueSearchResponseResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskJiraSearchGet(version, idProject, term, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a New Task.
         * @param {string} version 
         * @param {CreateTaskCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskPost(version: string, body?: CreateTaskCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskPost(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Task
         * @param {string} version 
         * @param {UpdateTaskCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskPut(version: string, body?: UpdateTaskCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskPut(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all tasks by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskTypeaheadTextGet(text: string, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TaskListResponse>>> {
            const localVarAxiosArgs = await TaskApiAxiosParamCreator(configuration).apiVversionTaskTypeaheadTextGet(text, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the list of all Tasks
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskSearchResponseResponse>> {
            return TaskApiFp(configuration).apiVversionTaskAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of Tasks by Employee Id And Project Id
         * @param {number} idEmployee 
         * @param {number} idProject 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(idEmployee: number, idProject: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskSearchResponseResponse>> {
            return TaskApiFp(configuration).apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(idEmployee, idProject, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of Tasks by Employee id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskGetByEmployeeIdIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskSearchResponseResponse>> {
            return TaskApiFp(configuration).apiVversionTaskGetByEmployeeIdIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of Tasks by Job id
         * @param {number} idJob 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskGetByJobIdJobGet(idJob: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskSearchResponseResponse>> {
            return TaskApiFp(configuration).apiVversionTaskGetByJobIdJobGet(idJob, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a task by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StatusCodeResult>> {
            return TaskApiFp(configuration).apiVversionTaskIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Task by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskByIdResponseResponse>> {
            return TaskApiFp(configuration).apiVversionTaskIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Jira Issue by Task
         * @param {number} idTask 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskJiraGetIdTaskGet(idTask: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<JiraIssueResponse>> {
            return TaskApiFp(configuration).apiVversionTaskJiraGetIdTaskGet(idTask, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Jira Issues filtered by Project and Search term, paginated.
         * @param {string} version 
         * @param {number} [idProject] 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskJiraSearchGet(version: string, idProject?: number, term?: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<JiraIssueSearchResponseResponse>> {
            return TaskApiFp(configuration).apiVversionTaskJiraSearchGet(version, idProject, term, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Task.
         * @param {string} version 
         * @param {CreateTaskCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskPost(version: string, body?: CreateTaskCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskResponse>> {
            return TaskApiFp(configuration).apiVversionTaskPost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Task
         * @param {string} version 
         * @param {UpdateTaskCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskPut(version: string, body?: UpdateTaskCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskResponse>> {
            return TaskApiFp(configuration).apiVversionTaskPut(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all tasks by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTaskTypeaheadTextGet(text: string, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TaskListResponse>> {
            return TaskApiFp(configuration).apiVversionTaskTypeaheadTextGet(text, version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @summary Gets the list of all Tasks
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskSearchResponseResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a list of Tasks by Employee Id And Project Id
     * @param {number} idEmployee 
     * @param {number} idProject 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(idEmployee: number, idProject: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskSearchResponseResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(idEmployee, idProject, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a list of Tasks by Employee id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskGetByEmployeeIdIdGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskSearchResponseResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskGetByEmployeeIdIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a list of Tasks by Job id
     * @param {number} idJob 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskGetByJobIdJobGet(idJob: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskSearchResponseResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskGetByJobIdJobGet(idJob, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes a task by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskIdDelete(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StatusCodeResult>> {
        return TaskApiFp(this.configuration).apiVversionTaskIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a Task by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskIdGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskByIdResponseResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get Jira Issue by Task
     * @param {number} idTask 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskJiraGetIdTaskGet(idTask: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<JiraIssueResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskJiraGetIdTaskGet(idTask, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search Jira Issues filtered by Project and Search term, paginated.
     * @param {string} version 
     * @param {number} [idProject] 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskJiraSearchGet(version: string, idProject?: number, term?: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<JiraIssueSearchResponseResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskJiraSearchGet(version, idProject, term, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Creates a New Task.
     * @param {string} version 
     * @param {CreateTaskCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskPost(version: string, body?: CreateTaskCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskPost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an existing Task
     * @param {string} version 
     * @param {UpdateTaskCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskPut(version: string, body?: UpdateTaskCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskPut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all tasks by typeahead
     * @param {string} text 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public async apiVversionTaskTypeaheadTextGet(text: string, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TaskListResponse>> {
        return TaskApiFp(this.configuration).apiVversionTaskTypeaheadTextGet(text, version, options).then((request) => request(this.axios, this.basePath));
    }
}
