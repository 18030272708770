import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Row, Col } from 'reactstrap';
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import ClientSelect from '../Project/ClientSelect';
import UserSelectRole from './UserSelectRole';
import '../../scss/User/FormUserAsEmployee.scss';

const FormUserAsClient = ({ idFe, securityRoles, roles, entityId, clients, handleClientChange, handleRoleChange, handleFormUserAsClientOpen, handleSave, edit }) => {

    const [disabled, setDisabled] = useState(false);

    const [hiddenRole, setHiddenRole] = useState(false);
    
    useEffect(() => {
        validate(entityId, securityRoles);
    }, [entityId, securityRoles]);
    
    const validate = (entityId, securityRoles) => {
        if (!entityId || securityRoles.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    };

    return (
        <Row className='mb-3'>
            <Col md={5}>
                <ClientSelect
                    handleClientChange={handleClientChange}
                    clients={clients}
                    selectedClient={entityId}
                    disabled={entityId && edit ? true : false}
                    newUser={true}
                    id={idFe}
                    securityRoles={securityRoles}
                    edit={true}
                    setHiddenRole={setHiddenRole}
                />
            </Col>
            <Col md={7} className='mt-2 mt-md-0'>
                <Row>
                    <Col md={10} className='mb-1 mb-md-0'>
                        <UserSelectRole
                            handleRoleChange={handleRoleChange}
                            selectedRoles={securityRoles}
                            roles={roles}
                            disabled={false}
                            id={idFe}
                            hiddenRole={hiddenRole}
                        />
                    </Col>
                    <Col md={2}>
                        <Row>
                            <Col md={6}>
                                <a title={intl.get('userAsEmployeeEdit.cancelButton.title')} className='td-icon' role="button" onClick={handleFormUserAsClientOpen}>
                                    <HiOutlineXCircle className={entityId ? 'iconCancelEdit' : 'iconCancel'}/>
                                </a>
                            </Col>
                            <Col md={6}>
                                <a title={intl.get('userAsEmployeeEdit.confirmButton.title')} className='td-icon' role="button" onClick={() => handleSave(idFe)}>
                                    <HiOutlineCheckCircle className={entityId && disabled ? 'iconSaveEdit saveDisabled' : entityId && !disabled ? 'iconSaveEdit' : !entityId && disabled ? 'iconSave saveDisabled' : 'iconSave'}/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default FormUserAsClient;