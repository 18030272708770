import React, { useState, useEffect, FC } from 'react';
import ContactForm from './ContactForm';
import '../scss/ContactInfo.scss';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { CreateContactInfoCommand, UpdateContactInfoCommand } from 'axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import contactType from '../fixture/contactType';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from './RequiredField';
import intl from 'react-intl-universal';
import { Row, Col } from 'reactstrap';
import Spinner from './Spinner';

type ContactInfoProps = {
    contactInfoId: number | null,
    contactInfos: any,
    setContactInfos: any,
    contactInfosId: any,
    setContactInfosId: any
    closeModal: any,
    // loading: boolean,
    // setLoading: Function
};

// const ContactInfo: FC<ContactInfoProps> = ({ contactInfoId, contactInfos, setContactInfos, contactInfosId, setContactInfosId, closeModal, loading, setLoading }) => {
const ContactInfo: FC<ContactInfoProps> = ({ contactInfoId, contactInfos, setContactInfos, contactInfosId, setContactInfosId, closeModal }) => {

    const defaultContactInfoInput = {
        contactType: '',
        idContactType: null,
        contactName: '',
        emailAddress: '',
        phone1: '',
        phone2: '',
        role: '',
    };

    const [input, setInput] = useState(defaultContactInfoInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [contactTypes, setContactTypes] = useState([]);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchContactInfo = async (contactInfoId: number) => {
        if (contactInfoId === null) {
            setInput(defaultContactInfoInput);
        } else{
            const response = await api.contactInfoApi.apiVversionContactInfoContactinfoIdGet(contactInfoId, "1", {}).then((response) => {
                if(response.data) {
                    setInput({
                        contactType: response.data?.data?.contactType?.name,
                        idContactType: response.data?.data?.contactType?.id,
                        contactName: response.data?.data?.contactName,
                        emailAddress: response.data?.data?.emailAddress,
                        phone1: response.data?.data?.phone1,
                        phone2: response.data?.data?.phone2,
                        role: response.data?.data?.role,
                    });
                    console.log("Success");
                };
            }).catch((error) => {
                if(error.response) {
                    console.log("Data :" , error.response.data);
                    console.log("Status :" + error.response.status);
                } else if(error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toast.error({ header: intl.get('contactInfo.toast.error.fetchContactInfo'), body: error.message });
            });
        }
    };

    const fetchContactType = () => {
        setContactTypes(contactType.contactType);
    };

    useEffect(() => {
        fetchContactInfo(contactInfoId);
        fetchContactType();
    }, [contactInfoId]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });

        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleContactTypeChange = (e) => {
        const inputVal = {
            ...input,
            idContactType: e.id,
            contactType: e.name
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idContactType: e.id
        }));
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.contactName) {
            errors.contactName = intl.get('validate.errors.isRequired');
        };
        if (!input.idContactType) {
            errors.idContactType = intl.get('validate.errors.isRequired');
        };
        if (!input.emailAddress) {
            errors.emailAddress = intl.get('validate.errors.isRequired');
        } else if (!/\S+@\S+\.\S+/.test(input.emailAddress)) {
            errors.emailAddress = intl.get('validate.errors.isInvalid');
        };
        if (!input.role) {
            errors.role = intl.get('validate.errors.isRequired');
        };
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleSave = async (e) => {
        setLoading(true);
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (contactInfoId) {
                let cmd: UpdateContactInfoCommand = {
                    phone1: input?.phone1,
                    phone2: input?.phone2,
                    role: input?.role,
                    idContactType: input?.idContactType,
                    contactName: input?.contactName,
                    emailAddress: input?.emailAddress,
                    id: contactInfoId
                };
                const response = await api.contactInfoApi.apiVversionContactInfoContactinfoPut("1", cmd, {}).then((response) => {
                    let contactEdited = response.data.data;
                    let copyContactInfos = contactInfos;
                    let newContactInfos = copyContactInfos.filter(contact => contact.id != contactInfoId);
                    setContactInfos([...newContactInfos, contactEdited]);
                    toast.success({ body: intl.get('contactInfo.toast.success.handleSave')});
                    setLoading(false);
                    setInput(defaultContactInfoInput);
                    closeModal();
                }).catch((error) => {
                    if(error.response) {
                        console.log("Data :" , error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if(error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('contactInfo.toast.error.handleSave'), body: error.message });
                    setLoading(false);
                });
            } else{
                let cmd: CreateContactInfoCommand = {
                    phone1: input?.phone1,
                    phone2: input?.phone2,
                    role: input?.role,
                    idContactType: input?.idContactType,
                    contactName: input?.contactName,
                    emailAddress: input?.emailAddress,
                };
                const response = await api.contactInfoApi.apiVversionContactInfoContactinfoPost("1", cmd, {}).then((response) => {
                    console.log(response.data);
                    let newContactInfo = response.data.data;
                    setContactInfos([...contactInfos, newContactInfo]);
                    const newContactInfoId = contactInfosId.includes(newContactInfo.id);
                    if(!newContactInfoId) {
                        setContactInfosId([...contactInfosId, newContactInfo.id]);
                    };
                    toast.success({ body: intl.get('contactInfo.toast.success.handleSave.add')});
                    setLoading(false);
                    setInput(defaultContactInfoInput);
                    closeModal();
                }).catch((error) => {
                    if(error.response) {
                        console.log("Data :" , error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if(error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('contactInfo.toast.error.handleSave.add'), body: error.message });
                    setLoading(false);
                });
            };
        }
    };

    const handleCancel = () => {
        fetchContactInfo(contactInfoId);
        setErrors({});
        closeModal();
    };

    return (
        <div className='container'>
            <div className='col my-3'>
                <h2 className='text-start mb-3'>{contactInfoId ? intl.get('contactInfo.title.edit') : intl.get('contactInfo.title.add')}</h2>
                <div className='row row-contact-info text-start mb-3'>
                    <ContactForm
                        errors={errors} 
                        input={input} 
                        handleInputChange={handleInputChange}
                        contactTypes={contactTypes}
                        selectedContactTypes={input.idContactType}
                        handleContactTypeChange={handleContactTypeChange}
                    />
                </div>
                <Row>
                    <Col md={4} >
                        <RequiredField/>
                    </Col>
                    <Col md={8} className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {
                            loading === true ?
                            <Spinner small={true} /> :
                            <>
                                <button type="button" className="btn btn-outline-dark" onClick={handleCancel}>{intl.get('cancel.button')}</button>
                                <button type="submit" className="btn btn-primary btn-save" onClick={handleSave} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.contactName === "" ? true : false}><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</button>
                            </>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ContactInfo;