import React, { FC } from 'react';
import { FaEdit } from 'react-icons/fa';
import { formatTime, toMinutes, unformatTime } from 'common/utils';
import { Timesheet } from 'axiosApi/models';
import InputMask from 'react-input-mask';
import { descriptors } from 'chart.js/dist/core/core.defaults';


type TimesheetRowProps = {
    duration: string,
    id: number,
    onClick: Function,
    idTimesheet: number,
    handleDurationChange: Function,
    disabled: boolean,
    handleDurationSave: Function,
    timesheet: Timesheet,
    date: string,
    description: string
}



const TimesheetDayDuration: FC<TimesheetRowProps> = ({ duration, id, onClick, idTimesheet, handleDurationChange, disabled, handleDurationSave, timesheet, date, description }) => {
    var saving = false;
    const localHandleDurationSave = (target) => {
        const duration = toMinutes(target.value)
        if ( !isNaN(duration) && ((timesheet && timesheet.duration !== duration) || (!timesheet && !!duration))) {
            handleDurationSave({ ...timesheet, duration: toMinutes(target.value), date: date });
        }
    }
    const handleKeyDown = (event) => {

        switch (event.key) {
            case "Enter":
                if(!saving)
                {
                    saving = true;
                    const duration = toMinutes(event.target.value)
                    if ( !isNaN(duration) && ((timesheet && timesheet.duration !== duration) || !timesheet && !!duration)) {
                        handleDurationSave({ ...timesheet, duration: toMinutes(event.target.value), date: date });
                    }
                    setTimeout(()=>{saving = false;},3000);
                }

                break;

        }
    }
    return (
        <div className="position-relative" key={id}>
            <InputMask
                mask="99:99"
                disabled={true}
                style={{ position: 'relative' }}
                value={duration}
                type="text"
                onChange={(e) => handleDurationChange(e.currentTarget.value)}
                onBlur={(e) => localHandleDurationSave(e.target)}
                onKeyDown={handleKeyDown}
                className="form-control col-6 white"
                placeholder="hh:mm"
                autoComplete="off"
                title={description}
            // onFocus={(e) => unformatTime(e.target.value)}
            ></InputMask>
            <div className={'input-group-flying-icon  icon-hand' + (description.length>0? ' text-green ':' text-dark-light') }>
                <FaEdit onClick={() => onClick(idTimesheet)}  title={description} ></FaEdit>
            </div>
            {/* <span className="input-group-append bg-white border-left-0">
                <span className="input-group-text bg-transparent">
                    
                </span>
            </span> */}
        </div>
    );
}

export default TimesheetDayDuration;



