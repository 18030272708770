import React from 'react';
import EmployeeRow from './EmployeeRow';
import { BiSortZA, BiSortAZ } from 'react-icons/bi';
import intl from 'react-intl-universal';

const EmployeeList = ({ searchResult, state, setState }) =>{

    const handleSortClick = (sort) => {
        setState({ ...state, orderValue: (state.sortValue == sort ? (state.orderValue == 'desc' ? 'asc' : 'desc') : 'asc'), sortValue: sort, currentPage: 1 });
    }

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' onClick={() => { handleSortClick('Name') }} scope="col">{intl.get('employeeList.table.thead.name')} {state.sortValue == 'Name' ? (state.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                        <th role='button' onClick={() => { handleSortClick('Lastname') }} scope="col">{intl.get('employeeList.table.thead.lastName')} {state.sortValue == 'Lastname' ? (state.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                        <th scope="col">{intl.get('employeeList.table.thead.email')}</th>
                        <th scope="col" className='text-center'>{intl.get('roleList.table.thead.actions')}</th>
                    </tr>
                </thead>
                {
                    searchResult.employees === null && state.queryTerm !== null ? 
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={6} className='text-center mb-0'>{intl.get('employeeList.notFound')}</td>
                        </tr>
                    </tbody> :
                    <tbody>
                        {
                            searchResult.employees && searchResult.employees.map((e,i)=> (
                                <EmployeeRow 
                                key={e.id} 
                                id={e.id} 
                                name={e.name} 
                                lastname={e.lastName} 
                                emailAddress={e.emailAddress}
                                className={i%2>0?'':'odd'}
                                />
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default EmployeeList;