import React from 'react';
import { MdOutlineAddCircle } from "react-icons/md";
import { Input } from 'reactstrap';

type JiraIssueRowProp = {
    jiraKey: string,
    summary: string,
    idJira: string,
    handleIssueAdd: Function,
    handleIssueSelect: Function,
    isIssueSelected: Function
}

const JiraIssueRow = ({ jiraKey, summary, idJira, handleIssueAdd, handleIssueSelect, isIssueSelected }: JiraIssueRowProp) => {

    return (
        <tr key={idJira}>
            <td><Input type='checkbox' checked={isIssueSelected(idJira)} onChange={(e) => { handleIssueSelect(idJira, e.currentTarget.checked) }}></Input> </td>
            <td style={{ wordBreak: 'keep-all', width: '100px' }}>{jiraKey}</td>
            <td>{summary}</td>
            <td>{idJira}</td>
            <td style={{ textAlign: 'center' }}><MdOutlineAddCircle title='Add Task' className='mx-2 edit-icon' role="button" onClick={() => { handleIssueAdd([{ key: jiraKey, idJira: idJira, summary: summary }]) }} ></MdOutlineAddCircle></td>
        </tr>
    )
}

export default JiraIssueRow;