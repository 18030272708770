import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';

type AddressTypeSelectProps = {
  handleAddressTypeChange: any,
  selectedAddressType: any,
  addressTypes: any,
};

const AddressTypeSelect: FC<AddressTypeSelectProps> = ({ addressTypes, selectedAddressType, handleAddressTypeChange }) => {

  return (
    <Select
      id="addressType"
      name="addressType"
      getOptionLabel={e => e.name}
      getOptionValue={e => e.id}
      placeholder={intl.get('addressForm.placeholder.addressType')}
      value={(addressTypes != null && selectedAddressType != null) ? (addressTypes.find((addressType) => addressType.id === selectedAddressType)) : ''}
      onChange={handleAddressTypeChange}
      isLoading={addressTypes == null}
      options={addressTypes}
    />
  );
}

export default AddressTypeSelect;