import React from 'react';
import { Form, Row, Label, Input } from 'reactstrap';
import intl from 'react-intl-universal';

const ProviderForm = ({ errors, input, handleInputChange, disabled }) => {
    return (
        <Form className='my-3'>
            <Row form ="true" >
                <div className='d-inline-flex col-12 col-md-6 flex-column'>
                    <Label for="providerName" className={`${errors.name ? 'text-danger' : ''}`}>
                        {intl.get('providerForm.name')} {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                    </Label>
                    <Input
                        id="providerName"
                        name="name"
                        placeholder={intl.get('providerForm.placeholder.name')}
                        value={input?.name}
                        onChange={handleInputChange}
                        disabled={disabled}
                    />
                </div>
            </Row>
        </Form>
    )
}

export default ProviderForm;