import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../scss/Roles/RoleNavbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'react-intl-universal';
import UserSelect from './UserSelect';
import StatusSelect from '../StatusSelect';
import ScopeSelect from './ScopeSelect';
import { FaSearch } from "react-icons/fa";
import RoleSearchBar from './RoleSearchBar';

const RoleNavbar = ({ users, status, scopes, onSearch, input }) => {
    
    const [queryString, setQueryString] = useState<string>('');
    const [idScope, setIdScope] = useState<string>(null);
    const [userEntityId, setUserEntityId] = useState<string>(null);
    const [idStatus, setIdStatus] = useState<string>(null);

    const handleSearchButton = () => {
        onSearch(queryString, idScope, userEntityId, idStatus);
    };

    const handleScopeChange = (e) => {
        if (e !== null) {
            setIdScope(e.id);
        } else {
            setIdScope(null);
        }
    };

    const handleUserChange = (e) => {
        if (e !== null) {
            setUserEntityId(e.id);
        } else {
            setUserEntityId(null);
        }
    };

    const handleStatusChange = (e) => {
        if (e !== null) {
            setIdStatus(e.id);
        } else {
            setIdStatus(null);
        }
    };

    return (
        <nav className='navbar container-fluid'>
            <div className='col-11 roleFilters'>
                <RoleSearchBar queryString={queryString} setQueryString={setQueryString}/>
                <ScopeSelect scopes={scopes} handleScopeChange={handleScopeChange} selectedScope={idScope}/>
                <UserSelect users={users} handleUserChange={handleUserChange} selectedUser={userEntityId} disabled={false} input={input} idNumber={null}/>
                <StatusSelect status={status} handleStatusChange={handleStatusChange} selectedStatus={idStatus} disabled={false} defaultValue={false} isClearable={true}/>
                <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;{intl.get('jiraIssueSearch.search.button')}</button>
            </div>
            <div className='col-1 newRole'>
                <Link to= '/role/new'><button className='btn btn-primary p-1 plusButton' type='button' id='newRole' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
                <ReactTooltip 
                    id='tooltipButton'
                    place='left'
                    content={intl.get('navbar.newRole.button')}
                />
            </div>
        </nav>
    )
}

export default RoleNavbar;