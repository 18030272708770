import React, { createContext, useContext, useState } from 'react';
import { MessageProps } from './ToastMessageQueue';
export type ToastMessageProviderProps = {
    messageQueue?: Array<MessageProps>,
    success?: Function,
    error?: Function,
    info?: Function,
    warning?: Function,
    DeleteToastMessage?: Function

}

export const ToastMessageQueueContext = createContext<ToastMessageProviderProps>({});

export const ToastMessageProvider = ({ children }) => {
    const [messageQueue, setMessageQueue] = useState<Array<MessageProps>>([]);
    const AddToastMessage = (message: MessageProps) => {
        const idMessage = messageQueue.length > 0 && messageQueue.at(messageQueue.length - 1).id ? messageQueue.at(messageQueue.length - 1).id + 1 : 0;
        setMessageQueue(prevMessages => [...prevMessages, { ...message, id: idMessage }]);
    }
    const DeleteToastMessage = id => {
        const index = messageQueue.findIndex(e => e.id === id);
        messageQueue.splice(index, 1);
        setMessageQueue([...messageQueue]);

    };
    const success = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'success' });
    }
    const error = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'error' });
    }
    const info = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'info' });
    }
    const warning = (message: MessageProps) => {
        AddToastMessage({ ...message, type: 'warning' });
    }
    return <ToastMessageQueueContext.Provider value={{
        messageQueue: messageQueue, error: error, success: success, warning: warning, info: info, DeleteToastMessage: DeleteToastMessage
    }} >
        {children}
    </ToastMessageQueueContext.Provider >
}

// export const ShowToastMessage = (message) => { useContext(ToastMessageQueueContext).ShowToastMessage(message) };

export function useToastMessageQueue() {
    return useContext(ToastMessageQueueContext);
}
