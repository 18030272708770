/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Employee API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { Settings }  from "../../common/settings";
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { StringResponse, UserEntityDetailsListResponse } from '../models';
const BASE_PATH = Settings.getInstance().BASE_API_URL_EMPLOYEE;

/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns current user information
         * @param {string} version 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginEntitiesGet: async (version: string, emailAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionLoginEntitiesGet.');
            }
            const localVarPath = `/api/v{version}/Login/entities`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            //No remover esta linea INTE-388 Fix necesario porque el token no está todavia en session mientras se termina el logueo
            //Tampoco se debe pisar con el authorization mas abajo antes de Userentity
            const localVarHeaderParameter = { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`, UserEntity: `${JSON.parse(sessionStorage.getItem("UserEntity"))?.id}`  } as any;
            //************************///
            const localVarQueryParameter = {} as any;


            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives Cognito Callback and issues security tokens
         * @param {string} version 
         * @param {string} [authorizationCode] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginPost: async (version: string, authorizationCode?: string, redirectUri?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionLoginPost.');
            }
            const localVarPath = `/api/v{version}/Login`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (authorizationCode !== undefined) {
                localVarQueryParameter['authorizationCode'] = authorizationCode;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refreshes security token
         * @param {string} version 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginRefreshPost: async (version: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionLoginRefreshPost.');
            }
            const localVarPath = `/api/v{version}/Login/refresh`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns current user information
         * @param {string} version 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginEntitiesGet(version: string, emailAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserEntityDetailsListResponse>>> {
            const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).apiVversionLoginEntitiesGet(version, emailAddress, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Receives Cognito Callback and issues security tokens
         * @param {string} version 
         * @param {string} [authorizationCode] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginPost(version: string, authorizationCode?: string, redirectUri?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StringResponse>>> {
            const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).apiVversionLoginPost(version, authorizationCode, redirectUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary refreshes security token
         * @param {string} version 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginRefreshPost(version: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StringResponse>>> {
            const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).apiVversionLoginRefreshPost(version, refreshToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary returns current user information
         * @param {string} version 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginEntitiesGet(version: string, emailAddress?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserEntityDetailsListResponse>> {
            return LoginApiFp(configuration).apiVversionLoginEntitiesGet(version, emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives Cognito Callback and issues security tokens
         * @param {string} version 
         * @param {string} [authorizationCode] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginPost(version: string, authorizationCode?: string, redirectUri?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StringResponse>> {
            return LoginApiFp(configuration).apiVversionLoginPost(version, authorizationCode, redirectUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refreshes security token
         * @param {string} version 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginRefreshPost(version: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StringResponse>> {
            return LoginApiFp(configuration).apiVversionLoginRefreshPost(version, refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @summary returns current user information
     * @param {string} version 
     * @param {string} [emailAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public async apiVversionLoginEntitiesGet(version: string, emailAddress?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserEntityDetailsListResponse>> {
        return LoginApiFp(this.configuration).apiVversionLoginEntitiesGet(version, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Receives Cognito Callback and issues security tokens
     * @param {string} version 
     * @param {string} [authorizationCode] 
     * @param {string} [redirectUri] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public async apiVversionLoginPost(version: string, authorizationCode?: string, redirectUri?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StringResponse>> {
        return LoginApiFp(this.configuration).apiVversionLoginPost(version, authorizationCode, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary refreshes security token
     * @param {string} version 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public async apiVversionLoginRefreshPost(version: string, refreshToken?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StringResponse>> {
        return LoginApiFp(this.configuration).apiVversionLoginRefreshPost(version, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}
