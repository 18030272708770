import React, { useState, useEffect, FC } from 'react';
import TimesheetForm from './TimesheetForm';
import 'scss/Timesheet/TimesheetForm.scss';
import { useApi } from 'api/ApiProvider';
import Api from 'axiosApi/api';
import { Project, Task, JiraIssue, GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse} from 'axiosApi/models';
import { TimesheetInput, fetchTimesheet, fetchTaskByIdProject, TimesheetEditProps } from './timesheetUtils';
import { formatDate, formatDateForAPI, toMinutes } from 'common/utils';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';

type TimesheetLogProps = {
    closeModal: Function | undefined,
    timesheetEditProps: TimesheetEditProps,
    projects: Project[],
    handleTimesheetSave: Function, 
    loading: boolean,
    timesheetRows: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } | undefined,
    setTimesheetRows: Function
}



const TimesheetLog: FC<TimesheetLogProps> = ({ closeModal, timesheetEditProps, projects, handleTimesheetSave, loading, timesheetRows, setTimesheetRows }) => {

    const defaultInput: TimesheetInput = {
        idEmployee: timesheetEditProps.idEmployee,
        idProject: timesheetEditProps.idProject,
        idTask: timesheetEditProps.idTask,
        description: '',
        date: timesheetEditProps.date,
        duration: '',
        startTime: null,
        idTimesheet: timesheetEditProps.idTimesheet,
        taskIsModified: false, 
        
    }

    const [input, setInput] = useState<TimesheetInput>(defaultInput);

    const [tasksByProject, setTasksByProject] = useState<Task[]>();

    const api: Api = useApi();
    const toast = useToastMessageQueue();

    const [errors, setErrors] = useState({});
    // if (timesheetEditProps.idTimesheet != input.idTimesheet || timesheetEditProps.date != input.date) {
    //     setInput(defaultInput);
    // }

    useEffect(() => {
        fetchTimesheet(api, input, setInput, setTasksByProject, toast);
    }, [timesheetEditProps.idTimesheet]);
    timesheetEditProps.idTimesheet == undefined && (useEffect(() => {
        fetchTaskByIdProject(api, timesheetEditProps.idProject, setTasksByProject, input, toast);
    }, [timesheetEditProps.idProject]));
    const handleInputChange = function (e: { target: { name: any; value: any; }; }) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleProjectChange = (idProject: number) => {

        setInput({
            ...input,
            idProject,
            idTask: (idProject ? input.idTask : null),
            taskIsModified: idProject != input.idProject

        });
        fetchTaskByIdProject(api, idProject, setTasksByProject, input, toast);
        setErrors(validate({
            ...input,
            idProject: idProject
        }));

    }
    const handleTaskChange = (idTask: number) => {
        setInput({
            ...input,
            idTask
        });
        setErrors(validate({
            ...input,
            idTask: idTask,
            taskIsModified: idTask != input.idTask
        }));
    }
    const handleDateChange = (date: Date) => {
        setInput({
            ...input,
            date
        });
        setErrors(validate({
            ...input,
            date: date
        }));
    }

    const handleTimesheetSaveLog = () => {
        const errors = validate(input);
        if (Object.keys(errors).length > 0) {
            setErrors({ ...errors })
        }
        else {
            if (timesheetEditProps.idTask != input.idTask || timesheetEditProps.date != input.date)
                handleTimesheetSave(api, input, closeModal, timesheetEditProps.idRow, { [timesheetEditProps.idTask]: { timesheetsGroupedByDate: { [formatDateForAPI(timesheetEditProps.date)]: {} } } });
            else
                if (timesheetEditProps.idTimesheet == null)
                    handleTimesheetSave(api, input, closeModal, timesheetEditProps.idRow);
                else
                    handleTimesheetSave(api, input, closeModal);
        }

    }

    const validate = function (input: TimesheetInput) {
        let errors = {};
        if (!input.idProject) {
            errors['idProject'] = intl.get('validate.errors.isRequired');
        }
        if (!input.idTask) {
            errors['idTask'] = intl.get('validate.errors.isRequired');
        }
        if (!input.date) {
            errors['date'] = intl.get('validate.errors.isRequired');
        }
        if (toMinutes(input.duration) < 1) {
            errors['duration'] = intl.get('validate.errors.isRequired');
        }


        return errors;
    };
    const formatDescriptionFromJira = (jiraIssue: JiraIssue): string => {
        return `${jiraIssue.key}: ${jiraIssue.summary}\n`;
    }
    const importDescriptionFromJira = () => {
        api.taskApi.apiVversionTaskJiraGetIdTaskGet(input.idTask, "1").then((response) => {
            if (response.data.data) {
                setInput(
                    {
                        ...input,
                        description: `${input.description} ${formatDescriptionFromJira(response.data.data)}`
                    });
            };
        }).catch((error) => {
            toast.error({ header: intl.get('timesheetLog.toast.error.formatDescriptionFromJira'), body: error.message });
        });
    }



    return (
        <div className='container'>
            <div className='col'>
                <h2 >{timesheetEditProps.idTimesheet ? 'Edit time log' : 'Add time log'}</h2>
                <div className="row timesheet-row text-start">
                    <TimesheetForm
                        id={"tmsd_" + timesheetEditProps.idTimesheet ? timesheetEditProps.idTimesheet : 'NEW'}
                        errors={errors}
                        input={input}
                        handleInputChange={handleInputChange}
                        projects={projects}
                        handleProjectChange={handleProjectChange}
                        tasks={tasksByProject}
                        handleTaskChange={handleTaskChange}
                        handleDateChange={handleDateChange}
                        closeModal={closeModal}
                        handleTimesheetSaveLog={handleTimesheetSaveLog}
                        importDescriptionFromJira={importDescriptionFromJira}
                        loading={loading}
                        timesheetRows={timesheetRows}
                        setTimesheetRows={setTimesheetRows}
                    />
                </div>
            </div>
        </div>
    )
}

export default TimesheetLog;