import React, { FC, useEffect, useState } from 'react';
import TimesheetAutocomplete from './TimesheetAutocomplete';
import TimesheetDayDuration from './TimesheetDayDuration'
import {addDays}  from 'date-fns';
import { GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse, Project, Task, Timesheet } from 'axiosApi/models';
import { TimesheetInput, convertTimesheetToTimesheetInput, fetchTaskByIdProject, handleTimesheetSave } from './timesheetUtils';
import Api from 'axiosApi/api';
import { formatDate, formatDateForAPI, toHoursAndMinutes } from 'common/utils';
import { FaTrashAlt } from "react-icons/fa";
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';

type TimesheetRowProps = {
    api: Api,
    prefixKey: string,
    firstDay: Date,
    projects: Project[] | undefined,
    durationClick: Function,
    workingWeekDays: number,
    timesheetRow: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse | undefined,
    preselectedTask: number | undefined,
    idEmployee: number,
    handleTimesheetSave: Function,
    deleteRow: Function,
    isTaskInTimesheetRows: Function,
    loading:boolean,
    dateChanged: boolean,
    setDateChanged: Function
}


const TimesheetRow: FC<TimesheetRowProps> = ({ api, prefixKey, firstDay, projects, durationClick, workingWeekDays, timesheetRow, preselectedTask, idEmployee, handleTimesheetSave, deleteRow, isTaskInTimesheetRows, loading, dateChanged, setDateChanged }: TimesheetRowProps, key) => {
    const [input, setInput] = useState<TimesheetInput>({
        idEmployee: idEmployee,
        idProject: timesheetRow ? timesheetRow.idProject : null,
        idTask: preselectedTask,
        description: '',
        date: new Date(),
        duration: '',
        startTime: null,
        taskIsModified: false
    });
    const [errors, setErrors] = useState({});
    const [tasks, setTasks] = useState<Task[]>();
    const toast = useToastMessageQueue();
    const [isloading, setIsLoading] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [taskId, setTaskId] = useState(null);


    useEffect(() => {
        
        if (input.idTask != null && isTaskInTimesheetRows(input.idTask) && preselectedTask == null) {
            setInput({ ...input, idProject: null, idTask: null });
        }
        if (input.idTask != preselectedTask && preselectedTask != null && input.idTask != null) {
            const idProject = timesheetRow ? timesheetRow.idProject : null;
            setInput({ ...input, idProject: timesheetRow ? timesheetRow.idProject : null, idTask: preselectedTask });
            setProjectId(timesheetRow ? timesheetRow.idProject : null);
            setTaskId(preselectedTask);
            if (idProject != null)
                fetchTaskByIdProject(api, idProject, setTasks, input, toast);
        }
        if (input.idTask == preselectedTask && preselectedTask != null && input.idTask != null && input.idProject == null) {
            const idProject = timesheetRow ? timesheetRow.idProject : null;
            setInput({ ...input, idProject: timesheetRow ? timesheetRow.idProject : null, idTask: preselectedTask });
            setProjectId(timesheetRow ? timesheetRow.idProject : null);
            setTaskId(preselectedTask);
            if (idProject != null)
                fetchTaskByIdProject(api, idProject, setTasks, input, toast);
        }

        if (input.idProject && !tasks) {
            fetchTaskByIdProject(api, input.idProject, setTasks, input, toast);
        }

    }, [input, preselectedTask, timesheetRow, prefixKey, key, projectId, taskId]);

    useEffect(() => {
        if (!dateChanged) {
            (projectId !== null && taskId !== null) && (input.idTask == null || input.idProject == null) ? setInput({ ...input, idProject: projectId, idTask: taskId }) : null;
        }
        else {
            setProjectId(null);
            setTaskId(null);
            setDateChanged(false);
        }
    },[input, projectId, taskId, dateChanged]);



    useEffect(() => {
        setInput({
            idEmployee: idEmployee,
            idProject: timesheetRow ? timesheetRow.idProject : null,
            idTask: preselectedTask,
            description: '',
            date: new Date(),
            duration: '',
            startTime: null,
            taskIsModified: false
        })

    }, [firstDay]);





    const handleDurationChange = (duration: String) => {
        setInput({
            ...input,
            duration: duration
        });
    }

    const handleDurationSave = (timesheet: Timesheet) => {
        handleTimesheetSave(api, { id: timesheet.id, idTask: timesheet.idTask ?? input.idTask, idProject: input.idProject, idEmployee: input.idEmployee, date: timesheet.date, duration: timesheet.duration, description: timesheet.description ?? '' }, () => { }, prefixKey);
    }

    const handleProjectChange = (idProject: number) => {
        setProjectId(idProject);
        if (idProject != input.idProject && timesheetRow) {
            
            setInput({
                ...input,
                idProject: idProject,
                idTask: (idProject ? input.idTask : null),
                taskIsModified: idProject != input.idProject

            });
            setIsLoading(true);
            fetchTaskByIdProject(api, idProject, setTasks, input, toast).then(()=>{setIsLoading(false)});
            if (idProject == null && timesheetRow)
                Object.keys(timesheetRow.timesheetsGroupedByDate).forEach((key) => { timesheetRow.timesheetsGroupedByDate[key].idTask = null })

        } else {
            setInput({
                ...input,
                idProject: idProject,
                idTask: (idProject ? input.idTask : null),
                taskIsModified: idProject != input.idProject

            });
            setIsLoading(true);
            fetchTaskByIdProject(api, idProject, setTasks, input, toast).then(()=>{setIsLoading(false)});;
            if (idProject == null && timesheetRow)
                Object.keys(timesheetRow.timesheetsGroupedByDate).forEach((key) => { timesheetRow.timesheetsGroupedByDate[key].idTask = null })

        }
        //TODO: Si idProject esta vacio y hay timelogs cargados en el row mostrar ! de atencion acá y en Task.
    }
    const handleTaskChange = (idTask: number) => {
        setTaskId(idTask);
        if (idTask != null && isTaskInTimesheetRows(idTask)) {
            toast.error({ body: intl.get('timesheetRow.toast.error.handleTaskChange')});
            return;

        }
        if (idTask != input.idTask && timesheetRow) {

            confirmAlert({
                title: intl.get('delete.modal.title'),
                message: 'All timesheet logs on this row will be updated. Are you sure you want to perform this action?',
                buttons: [
                    {
                        label: 'Cancel',
                        onClick: () => { }
                    },
                    {
                        label: 'Update',
                        onClick: () => {
                            setInput({
                                ...input,
                                idTask,
                                taskIsModified: idTask != input.idTask
                            });
                            if (timesheetRow) Object.keys(timesheetRow.timesheetsGroupedByDate).forEach((key) => {
                                timesheetRow.timesheetsGroupedByDate[key].idTask = idTask;
                                handleTimesheetSave(api, timesheetRow.timesheetsGroupedByDate[key], () => { }, toast);
                            })

                            toast.success({ body: intl.get('timesheetRow.toast.success.handleTaskChange')})
                        }
                    }
                ]
            });

        }
        else {
            setInput({
                ...input,
                idTask,
                taskIsModified: idTask != input.idTask
            });
            if (timesheetRow) Object.keys(timesheetRow.timesheetsGroupedByDate).forEach((key) => { timesheetRow.timesheetsGroupedByDate[key].idTask = idTask })
        }
    }

    const totalDurationByTask = (): string => {
        return toHoursAndMinutes(timesheetRow ? Object.keys(timesheetRow.timesheetsGroupedByDate).map((date) => { return timesheetRow.timesheetsGroupedByDate[date].duration }).reduce((subtotal, current) => subtotal + current, 0) : 0);
    }
    return (
        <div className="d-flex py-2 pr-2 border-bottom" key={prefixKey}>
            <div className="timesheet-first-column-width row flex-grow-1">
                <div className="col-6 pr-2">
                <TimesheetAutocomplete loading={isloading} type="project" className="timesheet-autocomplete" key={'tpi_' + prefixKey} prefixKey={'tpi_' + prefixKey} options={projects} hasProjectValue={input.idProject ? true: false} value={input.idProject ? input.idProject : projectId} setValue={handleProjectChange} placeholder={intl.get('timesheetRow.autocomplete.placeholder.project')} name="idProject" disabled={(timesheetRow != null && Object.keys(timesheetRow.timesheetsGroupedByDate).length > 0) || loading}></TimesheetAutocomplete>
                </div>
                <div className="col-6 pl-0">
                    <TimesheetAutocomplete loading={isloading} type="task" className="timesheet-autocomplete" key={'tti_' + prefixKey} prefixKey={'tti_' + prefixKey} options={tasks} hasProjectValue={input.idProject ? true: false} value={input.idTask ? input.idTask : taskId} setValue={handleTaskChange} placeholder={intl.get('timesheetRow.autocomplete.placeholder.task')} name="idTask" disabled={(timesheetRow != null && Object.keys(timesheetRow.timesheetsGroupedByDate).length > 0)|| loading} ></TimesheetAutocomplete>
                    {Object.keys(errors).includes('idTask') && (<p className='timesheet-danger'>{errors['idTask']}</p>)}
                </div>
            </div>
            {[...Array(workingWeekDays)].map((x, i) => {
                const day: Date = addDays(firstDay, i);
                const formatedDay: string = formatDateForAPI(day);
                const timesheet: Timesheet | undefined = (timesheetRow && timesheetRow.timesheetsGroupedByDate && Object.keys(timesheetRow.timesheetsGroupedByDate).includes(formatedDay)) ? timesheetRow.timesheetsGroupedByDate[formatedDay] : undefined;
                
                const idTimesheet = timesheet ? timesheet.id : undefined;
                return <div className='timesheet-column-width' key={'tsd_' + prefixKey + i}>
                    <TimesheetDayDuration
                        disabled={((idTimesheet == null && (input.idProject == null || input.idTask == null)) || loading) ? true : false}
                        duration={timesheet ? toHoursAndMinutes(timesheet.duration) : ''}
                        id={idTimesheet}
                        idTimesheet={idTimesheet}
                        onClick={() => {  durationClick({ date: day, idTimesheet: idTimesheet, idProject: input.idProject, idTask: input.idTask, idRow: prefixKey })}}
                        handleDurationChange={handleDurationChange}
                        handleDurationSave={handleDurationSave}
                        timesheet={timesheet}
                        date={formatedDay}
                        description={timesheet? timesheet.description:''}

                    ></TimesheetDayDuration> </div>
            })}

            <div className="timesheet-delete-column-width d-flex align-items-center justify-content-center">
                <span className="h6 m-2 text-dark-light font-weight-500 align-self-center"><FaTrashAlt tabIndex={1} role="button" onClick={() => { deleteRow(prefixKey.includes('new') ? prefixKey : input.idTask); }} ></FaTrashAlt></span>
            </div>
            <div className="timesheet-totals-column-width d-flex align-items-center justify-content-end">
                <span className="h6 m-0 text-dark-light font-weight-500 align-self-center">{totalDurationByTask()}</span>
            </div>
        </div>
    );
}

export default TimesheetRow;