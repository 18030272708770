import React, {useState, useEffect} from 'react';
import { Row, Col } from 'reactstrap';
import intl from 'react-intl-universal';
import { HiSearch } from 'react-icons/hi';
import { HiOutlineXCircle } from 'react-icons/hi';
import '../../scss/Roles/AssignedUsers.scss';

const AssignedUsers = ({ users, closeModal, searchedUser, setSearchedUser, idNumber }) => {

    const [filteredUsers, setFilteredUsers ] = useState(users);

    const handleSearchUser = () => {
        setFilteredUsers(users.filter(user => user.entityName.toLowerCase().includes(searchedUser.toLowerCase())));
    };

    useEffect(() => {
        setFilteredUsers(users);
    }, [searchedUser, users]);

    return (
        idNumber ?
        <>
            <Row className='mb-3'>
                <Col md={10}>
                    <div className="d-flex col-3 input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder={intl.get('assignedUsers.searchBar.placeholder')} 
                            aria-label="Search"
                            value={searchedUser}
                            onChange={e => setSearchedUser(e.target.value)}
                        />
                        {
                            searchedUser ?
                            <button 
                                className="btn btn-outline-secondary close p-0" 
                                type="button"
                                onClick={(e) => {
                                    setSearchedUser('');
                                }}
                            >
                                <HiOutlineXCircle className='mx-1 iconButton'/>
                            </button> :
                            null
                        }
                    </div>
                </Col>
                <Col md={2} className='text-end' >
                    <button type="button" className="btn btn-primary" onClick={handleSearchUser} ><HiSearch className='mb-1'/> {intl.get('searchBar.button')}</button>
                </Col>
            </Row>
            <Row>
                <div className="table-responsive container-overflow">
                    <table className='table table-hover table-striped'>
                        <tbody className='maxHeight' >
                            {
                                filteredUsers?.map((e, i) => (
                                    <tr key={i}>
                                        <td className='width'>{e.entityName} ({e.entityTypeName})</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </Row>
        </> :
        <>
            <Row className='mb-4'>
                <h2 className='mb-1'>{intl.get('assignedUsers.title')}</h2>
            </Row>
            <Row className='mb-3'>
                <Col md={10}>
                    <div className="d-flex col-3 input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder={intl.get('assignedUsers.searchBar.placeholder')} 
                            aria-label="Search"
                            value={searchedUser}
                            onChange={e => setSearchedUser(e.target.value)}
                        />
                        {
                            searchedUser ?
                            <button 
                                className="btn btn-outline-secondary close p-0" 
                                type="button"
                                onClick={(e) => {
                                    setSearchedUser('');
                                }}
                            >
                                <HiOutlineXCircle className='mx-1 iconButton'/>
                            </button> :
                            null
                        }
                    </div>
                </Col>
                <Col md={2} className='text-end' >
                    <button type="button" className="btn btn-primary" onClick={handleSearchUser} ><HiSearch className='mb-1'/> {intl.get('searchBar.button')}</button>
                </Col>
            </Row>
            <Row>
                <div className="table-responsive container-overflow">
                    <table className='table table-hover table-striped'>
                        <tbody>
                            {
                                filteredUsers?.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.entityName} ({e.entityTypeName})</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </Row>
            <div className="d-grid d-md-flex justify-content-md-end">
                <button type="button" className="btn btn-outline-danger" onClick={closeModal} >{intl.get('close.button')}</button>
            </div>
        </>
    )
}

export default AssignedUsers;