import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';
import '../../scss/Roles/ScopeSelect.scss';

type ScopeSelectProps = {
  handleScopeChange: any,
  selectedScope: any,
  scopes: any
};

const ScopeSelect: FC<ScopeSelectProps> = ({ scopes, selectedScope, handleScopeChange }) => {

  return (
    <Select
      id="scope"
      name="scope"
      getOptionLabel={e => e.scope}
      getOptionValue={e => e.id}
      placeholder={intl.get('ScopeSelect.placeholder.scope')}
      value={(scopes != null && selectedScope != null) ? (scopes.find((scope) => scope.id === selectedScope)) : ''}
      onChange={handleScopeChange}
      isLoading={scopes == null}
      options={scopes}
      isClearable={true}
      className='scope-select'
    />
  );
}

export default ScopeSelect;