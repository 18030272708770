import React from 'react';
import intl from 'react-intl-universal';
import { Row, Col } from 'reactstrap';
import { HiOutlineTrash } from 'react-icons/hi';
import ClientSelect from '../Project/ClientSelect';
import UserSelectRole from './UserSelectRole';
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';

const UserAsClient = ({ handleClientChange, clients, input, setInput, handleRoleChange, roles, securityRoles, idFe, hiddenRole, setHiddenRole }) => {

    const toast = useToastMessageQueue();

    const selectedClient = input.userDetails.filter(client => client.idFe === idFe);

    const deleteUserAsClient = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.userAsClient.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: () => {
                        const updatedUserDetails = input.userDetails.filter(user => user.idFe !== idFe);
                        setInput((prevInput) => ({
                            ...prevInput,
                            userDetails: updatedUserDetails
                        }));
                        toast.success({ body: intl.get('userAsClient.toast.success.handleDelete')});
                    },
                }
            ]
        });
    };

    return (
        <Row className='mb-2'>
            <Col md={6}>
                <ClientSelect
                    handleClientChange={handleClientChange}
                    clients={clients}
                    selectedClient={selectedClient[0]?.entityId}
                    disabled={false}
                    newUser={true}
                    id={idFe}
                    securityRoles={securityRoles}
                    edit
                    setHiddenRole={setHiddenRole}
                />
            </Col>
            <Col md={6} className='mt-2 mt-md-0'>
                <Row>
                    <Col md={11} className='mb-1 mb-md-0'>
                        <UserSelectRole
                            handleRoleChange={handleRoleChange}
                            selectedRoles={securityRoles}
                            roles={roles}
                            disabled={false}
                            id={idFe}
                            hiddenRole={hiddenRole}
                        />
                    </Col>
                    <Col md={1}>
                        <a title={intl.get('employeeRow.deleteButton.title')} className='td-icon' role="button" onClick={deleteUserAsClient}>
                            <HiOutlineTrash className='iconTrash'/>
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default UserAsClient;
