import React, { useState, useEffect } from 'react';
import RoleNavbar from './RoleNavbar';
import RoleList from './RoleList';
import Spinner from '../Spinner';
import '../../scss/Roles/Roles.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import {USER_STATUS} from '../../common/constants';
import { SecurityRole } from 'axiosApi/models';
import RolePagination from './RolePagination';
import ItemsSelect from '../ItemsSelect';

export type RolesInput = {
    queryTerm?: string | null,
    currentPage?: number,
	sortValue?: string | null,
	orderValue?: string | null,
    idStatus: number,
    userEntityId: number,
    idScope: number | null
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: SecurityRole[]
};

const Roles = () => {

    const [input, setInput] = useState<RolesInput | null>({
        queryTerm: "",
        currentPage: 1,
		sortValue: "Name",
		orderValue: "asc",
        idStatus: null,
        userEntityId: null,
        idScope: null
	});
    
	const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: []
	});
    
    const api: Api = useApi();

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageRoles'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageRoles'), 10) || 10);
	}, []);

    const toast = useToastMessageQueue();

    const [scopes, setScopes] = useState(null);

    const [users, setUsers] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const fetchSecurityRoles = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        if (input.idScope) {
            f = [`scopes:${input.idScope}`];
        }
        if (input.userEntityId) {
            f = f || [];
            f.push(`userentity:${input.userEntityId}`);
        }
        const response = await api.securityRoleApi.apiVversionSecurityRoleSearchGet ("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
            if (response.data.data === null) {
                setSearchResult({
                    totalRecords: null,
                    queryResult: null
                });
            } else {
                setSearchResult({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            setLoading(false);
            console.log("Success");
        }).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('roles.toast.error.fetchSecurityRoles'), body: error.message });
			setLoading(false);
		});
    };

    const fetchScopes = async () => {
        const response = await api.scopeApi.apiVversionScopeAllGet("1", input.currentPage, limit, {}).then((response) => {
            if (response.data.data === null) {
				setScopes({
					totalRecords: null,
					queryResult: null
				});
			} else {
                setScopes({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            console.log("Success");
        }).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('roleDetail.toast.error.fetchScopes'), body: error.message });
		});
    };

    const fetchUsers = async () => {
        const response = await api.userApi.apiVversionUserUserentitiesGet("1", input.currentPage, 200, {}).then((response) => {
            if (response.data.data === null) {
				setUsers({
					totalRecords: null,
					queryResult: null
				});
			} else {
                setUsers({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            console.log("Success");
        }).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('roles.toast.error.fetchUsers'), body: error.message });
		});
    };

    useEffect(() => {
        fetchSecurityRoles();
        fetchScopes();
        fetchUsers();
	}, [input, limit]);

	const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const onSearch = (queryString, idScope, userEntityId, idStatus) => {
		setInput({ ...input, queryTerm: queryString, idScope: idScope, userEntityId: userEntityId, idStatus: idStatus, currentPage: 1 });
	};

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('roles.header')}</h2>
                </div>
                <div className='card-body'>
                    <div>
                        <RoleNavbar 
                            users={users?.queryResult}
                            status={USER_STATUS}
                            scopes={scopes?.queryResult}
                            onSearch={onSearch}
                            input={input}
                        />
                        {
                            loading === true ?
                            <Spinner /> :
                            <>
                                <RoleList searchResult={searchResult} setSearchResult={setSearchResult} setLoading={setLoading}/>
                                <div className="pagination row w-100">
                                    <div className="col-10 col-xl-11 d-flex justify-content-center">
                                        <RolePagination 
                                            handlePageClick={handlePageClick}
                                            limit={limit} 
                                            searchResult={searchResult} 
                                            nextPage={nextPage} 
                                            prevPage={prevPage} 
                                            input={input} 
                                            pageCount={pageCount} 
                                            setpageCount={setpageCount} 
                                            initialPage={initialPage} 
                                            finalPage={finalPage} 
                                        />
                                    </div>
                                    <div className="col-2 col-xl-1 d-flex justify-content-end">
                                        <ItemsSelect 
                                            input={input}
                                            setInput={setInput}
                                            limit={limit} 
                                            setLimit={setLimit} 
                                            storageKey="itemsPerPageRoles"
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roles;