import Api from 'axiosApi/api';
import { CreateTimesheetCommand, UpdateTimesheetCommand, GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse, Timesheet } from 'axiosApi/models';
import { convertDateAsUTC, toHoursAndMinutes, toMinutes } from 'common/utils';
import { ToastMessageProviderProps } from 'components/ToastMessages/ToastMessageProvider';
import { format, setHours, setMinutes } from 'date-fns';
import intl from 'react-intl-universal';

export type TimesheetEditProps = {
    date: Date,
    idEmployee?: number | undefined,
    idTimesheet?: number,
    idProject?: number,
    idTask?: number,
    idRow?: string
}

export type TimesheetInput = {
    idTimesheet?: number | null,
    idEmployee: number,
    idProject?: number | null,
    projectInputValue?: string,
    idTask?: number | null,
    description?: string,
    date?: Date,
    duration?: String,
    startTime?: String,
    taskIsModified: Boolean
}


export const fetchTaskByIdProject = async (api: Api, idProject: number, setTasksByProject: Function, input: TimesheetInput, toast: ToastMessageProviderProps) => {

    if (input.idEmployee && idProject) {
        const res = await api.taskApi.apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(input.idEmployee, idProject, "1", {}).then((response) => {
            if (response.data.data) {

                setTasksByProject(response.data.data?.queryResult);
            };
        }).catch((error) => {
            toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.fetch') });
        });
    } else if (!!!idProject) {
        setTasksByProject([]);
    }
};

export const fetchTimesheet = async (api: Api, input, setInput: Function, setTasksByProject: Function, toast) => {
    if (input.idTimesheet) {
        const res = await api.timesheetApi.apiVversionTimesheetIdGet(input.idTimesheet, "1", {}).then((response) => {
            if (response.data.data) {
                setInput(
                    {
                        ...input,
                        idEmployee: response.data.data.timesheet.idEmployee,
                        idProject: response.data.data?.timesheet?.task.job.idProject,
                        idTask: response.data.data.timesheet.idTask,
                        description: response.data.data.timesheet.description,
                        date: response.data.data.timesheet.date,
                        duration: toHoursAndMinutes(response.data.data.timesheet.duration),
                        startTime: format(new Date(response.data.data.timesheet.startTime), "hh:mm")
                    });
                fetchTaskByIdProject(api, response.data.data?.timesheet?.task.job.idProject, setTasksByProject, input, toast);
            };
        }).catch((error) => {
            toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.fetch') });
        });
    }
};



export const handleTimesheetSave = async (api: Api, input: TimesheetInput, closeModal: Function, toast): Promise<{ [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }> => {

    if (!Object.keys(input).includes("taskIsModified")) {
        input = convertTimesheetToTimesheetInput(input)
    }

    const startTime = input.startTime ? (setMinutes(setHours(new Date(input.date), parseInt(input.startTime?.split(":")[0])), parseInt(input.startTime?.split(":")[1]))) : input.date;

    if (!input.idTimesheet) {
        let cmd: CreateTimesheetCommand = {
            idEmployee: input?.idEmployee,
            idTask: input?.idTask,
            description: input?.description,
            date: convertDateAsUTC(input?.date.toISOString()),
            duration: toMinutes(input?.duration),
            startTime: convertDateAsUTC(startTime.toISOString())
        }
        return await api.timesheetApi.apiVversionTimesheetPost("1", cmd, {}).then((response) => {
            if (!response.data.errors) {
                closeModal();
                const responseReturn = { [response.data.data.idTask]: { idProject: input.idProject, timesheetsGroupedByDate: { [format(new Date(response.data.data.date.toString().replace("Z", "")), "Y-MM-dd")]: response.data.data } } };
                toast.success({ body: intl.get('timesheetComponent.toast.saveConfirmation')  })
                return responseReturn;
            }
            else {
                toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.saveFailed')  });
            }
        }).catch((error) => {
            if(error.response?.data?.Message == "JobNotAssignedForDate")
                toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.JobNotAssignedForDate') });
            else
                toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.saveFailed') });
            return null;
        });
    }
    else {
        let cmd: UpdateTimesheetCommand = {
            idEmployee: input?.idEmployee,
            idTask: input?.idTask,
            description: input?.description,
            date: input?.date,
            duration: toMinutes(input?.duration),
            startTime: startTime,
            id: input.idTimesheet
        }
        return await api.timesheetApi.apiVversionTimesheetPut("1", cmd, {}).then((response) => {
            if (!response.data.errors) {
                closeModal();
                toast.success({  body: intl.get('timesheetComponent.toast.saveConfirmation') })
                return { [response.data.data.idTask]: { idProject: input.idProject, timesheetsGroupedByDate: { [format(new Date(response.data.data.date), "Y-MM-dd")]: response.data.data } } };
            }
            else {
                toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.saveFailed') });
                return {}
            }
        }).catch((error) => {
            toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.saveFailed')  });
            return {}
        });
    }
};


export const handleTimesheetDelete = async (api: Api, idTimesheet: number, toast): Promise<boolean> => {

    if (idTimesheet != null) {
        return await api.timesheetApi.apiVversionTimesheetIdDelete(idTimesheet, "1", {}).then((response) => {
            if (response.status == 200) {
                toast.success({  body: intl.get('timesheetComponent.toast.deleteconfirmation') })
                return true;
            }
            else {
                toast.error({ header: 'Error', body: intl.get('timesheetComponent.toast.error.saveFailed')  });
            }
        });
    }
};

export const convertTimesheetToTimesheetInput = (timesheet: any): TimesheetInput => {
    return { idEmployee: timesheet.idEmployee, date: timesheet.date, idTimesheet: timesheet.id, idTask: timesheet.idTask, idProject: timesheet.idProject, duration: toHoursAndMinutes(timesheet.duration), description: timesheet.description, taskIsModified: false }
}


export const convertTimesheetRowsToNewRowsWithTaskId = (timesheetRows: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }): { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } => {
    let response: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } = {};
    Object.keys(timesheetRows).forEach((idTask) => {
        response[idTask] = { idProject: timesheetRows[idTask].idProject, timesheetsGroupedByDate: {} };
    })
    return response;
}