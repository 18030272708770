import React, { useEffect } from 'react';
import '../../scss/EmployeePagination.scss';

const EmployeePagination = ({ handlePageClick, limit, searchResult, nextPage, prevPage, state, pageCount, setpageCount, initialPage, finalPage }) => {

    useEffect(() => {
        setpageCount(Math.ceil(searchResult.totalRecords / limit));
    }, [searchResult, limit]);

    const pageNumbers: number[] = [];

    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className='pagination' aria-label='...'>
            <ul className='pagination justify-content-center'>
                <li className={state.currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='/employees#' aria-label="Initial" onClick={() => initialPage()}>
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li className={state.currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='/employees#' aria-label="Previous" onClick={() => prevPage()}>
                        <span aria-hidden="true">&lt;</span>
                    </a>
                </li>
                {pageNumbers.map(num => (
                    <li className={state.currentPage === num ? 'page-item active' : 'page-item'} key={num}>
                        <a className='page-link' href='/employees#' onClick={() => handlePageClick(num)}>{num}</a>
                    </li> 
                ))}
                <li className={state.currentPage === pageCount ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='/employees#' aria-label="Next" onClick={() => nextPage()}>
                        <span aria-hidden="true">&gt;</span>
                    </a>
                </li>
                <li className={state.currentPage === pageCount ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='/employees#' aria-label="Final" onClick={() => finalPage()}>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default EmployeePagination;