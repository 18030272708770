import React, { useState, useEffect, useRef } from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";

const DynamicAutocomplete: React.FC<
  AutocompleteProps<any, true, false, false>
> = (props) => {
  const [limitTags, setLimitTags] = useState(1);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false); // We use this to prevent the popper from closing when the input is focused.

  useEffect(() => {
    const calculateLimitTags = () => {
      if (autocompleteRef.current) {
        const parent = autocompleteRef.current.querySelector(".MuiInputBase-root");
        const parentStyles = window.getComputedStyle(parent);
        const parentWidth = parent.clientWidth - parseFloat(parentStyles.paddingLeft) - parseFloat(parentStyles.paddingRight);

        // Width of the input. Initial approach to hide it when unfocused instead but this breaks Autocomplete.
        const input = autocompleteRef.current.querySelector( ".MuiInputBase-input");
        const inputStyles = window.getComputedStyle(input);
        const inputWidth = parseFloat(inputStyles.paddingLeft) + parseFloat(inputStyles.paddingRight) + parseFloat(inputStyles.minWidth);

        // Width of the tag (the "+1" for hidden items). Can't get the size if it doesn't exist, so we use 2x the font size of the input as a heuristic.
        const tagWidth = parseFloat(inputStyles.fontSize) * 2;

        let totalChipWidth = inputWidth + tagWidth;
        let chipsThatFit = 0;

        const chips = autocompleteRef.current.querySelectorAll(".MuiChip-root");
        chips.forEach((chip) => {
          const chipWidth =
            chip.clientWidth + parseInt(window.getComputedStyle(chip).marginRight) + parseInt(window.getComputedStyle(chip).marginLeft);
          if (totalChipWidth + chipWidth <= parentWidth) {
            totalChipWidth += chipWidth;
            chipsThatFit += 1;
          }
        });
        setLimitTags(chipsThatFit);
      }
    };
    calculateLimitTags();
    window.addEventListener("resize", calculateLimitTags);
    return () => {
      window.removeEventListener("resize", calculateLimitTags);
    };
  }, [props.value, isFocused]);

  return (
    <Autocomplete
      ref={autocompleteRef}
      disableClearable
      disablePortal
      forcePopupIcon={false}
      handleHomeEndKeys
      multiple={true}
      selectOnFocus
      limitTags={limitTags}
      clearOnBlur={false} // Prevents closing the popper when the autocomplete expands due to limitTags, we handle that manually:
      open={isFocused}
      onOpen={() => setIsFocused(true)}
      onClose={(event, reason) => {
        if (reason === "blur") {
          setIsFocused(false);
        }
      }}
      {...props} // We pass everything else.
    />
  );
};

export default DynamicAutocomplete;
