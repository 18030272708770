import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';
import '../../scss/Roles/UserSelect.scss';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';

type UserSelectProps = {
  handleUserChange: any,
  selectedUser: any,
  users: any,
  disabled: boolean,
  input: any,
  idNumber: any
};

const UserSelect: FC<UserSelectProps> = ({ users, selectedUser, handleUserChange, disabled, input, idNumber }) => {

  let idUsers = input?.idUsers;

  let filteredUsers = idNumber ? users?.filter(user => !idUsers?.includes(user.id)) : users;

  const usersSortedByName = sortCollection(filteredUsers, "entityName", SORT_TYPES.ASC);

  return (
    <Select
      id="users"
      name="users"
      getOptionLabel={e => `${e.entityName} (${e.entityTypeName})`}
      getOptionValue={e => e.id}
      placeholder={intl.get('userSelect.placeholder.user')}
      value={ idNumber ? (input?.users != null && selectedUser != null) ? (input?.users?.find((user) => user.id === selectedUser)) : '' : (users != null && selectedUser != null) ? (users?.find((user) => user.id === selectedUser)) : '' }
      onChange={handleUserChange}
      isLoading={input?.users == null && users == null}
      options={usersSortedByName}
      isDisabled={disabled}
      className={`${idNumber ? '' : 'user-select'}`}
      isClearable={true}
    />
  );
}

export default UserSelect;