
import React, { useEffect, useState } from 'react';
import 'scss/JiraIssuesListPagination.scss';

const JiraIssuesListPagination = ({ setPageNum, pageNum, pageSize, totalRecords }) => {

    const [pageCount, setPageCount] = useState(Math.ceil(totalRecords / pageSize));
    useEffect(() => {
        setPageCount(Math.ceil(totalRecords / pageSize));
    }, [totalRecords, pageSize]);
    return (
        pageCount > 0 && <nav className='pagination justify-content-center' aria-label='...'>
            <ul className='pagination justify-content-center mb-0'>
                <li className={pageNum === 0 ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='#' aria-label="Initial" onClick={(e) => { e.preventDefault(); setPageNum(0) }}>
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li className={pageNum === 0 ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='#' aria-label="Previous" onClick={(e) => { e.preventDefault(); setPageNum(pageNum - 1) }}>
                        <span aria-hidden="true">&lt;</span>
                    </a>
                </li>
                {[...Array(pageCount)].map((num, index) => (
                    <li className={pageNum === index ? 'page-item active disabled' : 'page-item'} key={index}>
                        <a className='page-link' href='#' onClick={(e) => { e.preventDefault(); setPageNum(index) }}>{index + 1}</a>
                    </li>
                ))}
                <li className={pageNum + 1 === pageCount ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='#' aria-label="Next" onClick={(e) => { e.preventDefault(); setPageNum(pageNum + 1) }}>
                        <span aria-hidden="true">&gt;</span>
                    </a>
                </li>
                <li className={pageNum + 1 === pageCount ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href='#' aria-label="Final" onClick={(e) => { e.preventDefault(); setPageNum(pageCount) }}>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default JiraIssuesListPagination;