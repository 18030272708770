import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import Spinner from '../Spinner';
import { HiOutlineSave } from 'react-icons/hi';
import Api from 'axiosApi/api';
import { useApi } from '../../api/ApiProvider';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';

const TimeOffReject = ({ closeModal }) => {

    const defaultTimeOffRejectInput = {
        description: ""
    };

    const [input, setInput] = useState(defaultTimeOffRejectInput);

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    };

    const handleCancel = () => {
        setInput(defaultTimeOffRejectInput);
        setErrors({});
        closeModal();
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.description) {
            errors.description = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleSave = async (e) => {
        // setLoading(true);
        e.preventDefault();
        const errors = validate(input);
        let cmd = {
            description: input?.description
        }
        // DESCOMENTAR CUANDO LA API HAYA SIDO CREADA:
        // const response = await api.timeOffApi.apiVversionTimeOffTimeOffPost("1", cmd, {}).then((response) => {
        //     let reject = response.data.data;
        //     toast.success({ body: intl.get('timeOffReject.toast.success.handleSave.add')});
        //     setLoading(false);
        //     setInput(defaultTimeOffRejectInput);
        //     closeModal();
        // }).catch((error) => {
        //     if (error.response) {
        //         console.log("Data :", error.response.data);
        //         console.log("Status :" + error.response.status);
        //     } else if (error.request) {
        //         console.log(error.request);
        //     } else {
        //         console.log("Error", error.message);
        //     }
        //     toast.error({ header: intl.get('timeOffReject.toast.error.handleSave.add'), body: error.message });
        //     setLoading(false);
        // });
    };

    return (
        <div className='container'>
            <div className="card-header">
                <h2 className='text-start'>{intl.get('timeOffReject.title')}</h2>
            </div>
            <div className="card-body">
                <FormGroup>
                    <Col md={12}>
                        <Label for="description" className='d-flex text-start'>{intl.get('timeOffReject.description')}</Label>
                        <Input
                            id="description"
                            name="description"
                            placeholder={intl.get('timeOffReject.placeholder.description')}
                            type="textarea"
                            rows="5"
                            value={input.description ? input.description : ''}
                            onChange={handleInputChange}
                        />
                    </Col>
                </FormGroup>
            </div>
            <div>
                <Col md={12} className="d-grid gap-2 d-md-flex justify-content-md-end">
                    {
                        loading === true ?
                        <Spinner small={true} /> :
                        <>
                            <button type="button" className="btn btn-outline-dark" onClick={handleCancel}>{intl.get('cancel.button')}</button>
                            <button type="submit" className="btn btn-primary" onClick={handleSave} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.description === "" ? true : false}><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</button>
                        </>
                    }
                </Col>
            </div>
        </div>
    )
}

export default TimeOffReject;
