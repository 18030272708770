import React from "react";
import CalendarEvent from "components/Time Off/CalendarEvent";
import Modal, { ModalSizes } from "../Modal";

const ModalCalendarEvent = ({ isOpen, closeModal, eventId, updateEvents }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <CalendarEvent 
                closeModal={closeModal} 
                eventId={eventId} 
                updateEvents={updateEvents}
            />
        </Modal>
    )
}

export default ModalCalendarEvent;