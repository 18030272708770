import React from "react";
import Modal, { ModalSizes } from "../Modal";
import CalendarEvent from "./CalendarEvent";

const ModalCalendar = ({ isOpen, closeModal, eventId, updateEvents }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <CalendarEvent closeModal={closeModal} eventId={eventId} updateEvents={updateEvents}/>
        </Modal>
    )
}

export default ModalCalendar;