import React, { MouseEventHandler, useState } from "react";
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import 'scss/ButtonWithOptions.scss';


export enum ButtonWithOptionsType { item, header, divider };
type ButtonOptions = {
    title: string,
    onClick: MouseEventHandler<HTMLElement>,
    type: ButtonWithOptionsType,
    disabled?: boolean
}
type ButtonWithOptionsProps = {
    onClick: MouseEventHandler<HTMLElement>,
    options: ButtonOptions[],
    className?: string,
    itemClassName?: string,
    disabled?: boolean,
    outline?: boolean,
    
};

export const ButtonWithOptions = ({ options, children, className, itemClassName, onClick, disabled = false, outline = false }: React.PropsWithChildren<ButtonWithOptionsProps>) => {
    const [dropdownOpen, setDropDownOpen] = useState(false);
    const toggle = () => {
        setDropDownOpen(!dropdownOpen);
    }

    return (
        <ButtonGroup>
            <Button onClick={onClick} disabled={disabled} type="submit" outline={outline} className={className}>{children}</Button>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className={`button-with-options`} disabled={disabled}>
                <DropdownToggle caret disabled={disabled} outline={outline}  className={className}></DropdownToggle>
                <DropdownMenu className="dropdown-menu-start">
                    {options.map((option, index) => <DropdownItem key={index} className={itemClassName} onClick={option.onClick} disabled={option.disabled} divider={option.type === ButtonWithOptionsType.divider} header={option.type === ButtonWithOptionsType.header} >{option.title ?? ''}</DropdownItem>)}
                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>
    )
}
