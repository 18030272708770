export const ROLES = {
    billingReader: "Billing.Read",
    billingWriter: "Billing.Writer",
    projectReader: "Project.Reader",
    projectWriter: "Project.Writer",
    reportReader: "Report.Reader",
    employeeWriter: "Employee.Write",
    employeeReader: "Employee.Reader",
    clientWriter: "Client.Writer",
    clientReader: "Client.Reader",
    timesheetReader: "Timesheet.Reader",
    timesheetEditor: "Timesheet.Editor",
    admin: "Admin",
    default:"us-east-1_yuHMf1KNJ_AzureAD"
  };

  export const SCOPES = {
    "clients.read" : "clients.read", 
    "clients.edit" : "clients.edit",
    "clients.delete" : "clients.delete",
    "employees.read" : "employees.read", 
    "employees.edit" : "employees.edit",
    "employees.delete" : "employees.delete",
    "projects.read" : "projects.read",
    "projects.edit" : "projects.edit",
    "projects.employees.edit" : "projects.employees.edit",
    "projects.employees.add" : "projects.employees.add",
    "projects.employees.delete" : "projects.employees.delete",
    "projects.task.edit" : "projects.task.edit",
    "projects.task.add" : "projects.task.add",
    "projects.task.delete" : "projects.task.delete",
    "projects.job.edit" : "projects.job.edit",
    "projects.job.add" : "projects.job.add",
    "projects.job.delete" : "projects.job.delete",
    "projects.delete" : "projects.delete",
    "reports.billing.read":"reports.billing.read",
    "reports.timesheet.read":"reports.timesheet.read",
    "budget.read" : "budget.read",
    "budget.edit" : "budget.edit",
    "timesheet.read" : "timesheet.read",
    "timesheet.edit" : "timesheet.edit",
    "reports.read" : "reports.read",
    "providers.read" : "providers.read",
    "providers.edit" : "providers.edit",
    "providers.delete" : "providers.delete",
    "users.read" : "users.read",
    "users.edit" : "users.edit",
    "users.delete" : "users.delete",
    "pto.read": "pto.read",
    "nonworkingday.read": "nonworkingday.read"
  };
