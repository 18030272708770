import React, { useMemo, useState } from "react";
import "../../scss/Time Off/FileUploader.scss";
import intl from "react-intl-universal";
import { useDropzone } from "react-dropzone";
import { Col, Row } from "reactstrap";
import { HiDocument } from "react-icons/hi";

const StyledDropzone = ({ files, setInput, disabled = false }) => {
  
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
  useDropzone({
    accept: { "image/png": [], "image/jpeg": [], "application/pdf": [] },
    onDrop: (file) => {
      if (
        file?.[0]?.type === "image/jpeg" ||
        file?.[0]?.type === "image/png" ||
        file?.[0]?.type === "application/pdf"
      ) {
        setInput((prevInput) => ({
          ...prevInput,
          attachments: prevInput.attachments ? [...prevInput.attachments, file[0]] : [file[0]]
        }));
      }
    },
    noClick: true,
    disabled: disabled
  });

  const handleDelete = (index) => {
    const temp = [...files];
    temp.splice(index, 1);
    setInput((prevInput) => ({
      ...prevInput,
      attachments: temp
    }));
  };

  const className = useMemo(() => {
    let classNames = "base-style";
    if (files[0]) classNames += " attachmentRow";
    if (isFocused) classNames += " focused-style";
    if (isDragAccept) classNames += " accept-style";
    if (isDragReject) classNames += " reject-style";
    return classNames;
  }, [isFocused, isDragAccept, isDragReject, files]);

  return (
    <div>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        {!files[0] ? (
          <Row className="justify-content-around">
            <Col md={12}>
              <div>
                <HiDocument className="iconFileTimeOff" />
              </div>
              <b>{intl.get("timeOff.attached.files")}</b>
              <div className="dropZoneText">
                <p>
                  {intl.get("timeOff.file.type")}
                  <br></br>
                  {intl.get("timeOff.file.size")}
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="attachmentList">
            <Col md={12}>
              <h6 className="attachmentText">
                {intl.get("timeOff.attached.files")}
              </h6>
              {files.map((file, key) => {
                return (
                  <div key={key} className="container-fluid request gap-3">
                    <p className="attachmentText" key={key}>
                      {file.path}
                    </p>
                    {
                      !disabled ?
                      <button
                        className="btn deleteFileButton p-2"
                        type="button"
                        id="delete"
                        onClick={() => handleDelete(key)}
                        data-tooltip-id="tooltipButton"
                      >
                        {intl.get("timeOff.delete")}
                      </button> 
                      : null
                    }
                  </div>
                );
              })}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default StyledDropzone;
