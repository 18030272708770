import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Clients from "./components/Clients";
import ClientDetail from "./components/ClientDetail";
import Employees from "./components/Employee/Employee";
import EmployeeDetail from "./components/Employee/EmployeeDetail";
import { DashboardComponent } from "./components/Dashboard/DashboardComponent";
import Projects from "./components/Project/Projects";
import ProjectDetail from "./components/Project/ProjectDetail";
import ApiProvider from './api/ApiProvider';
import { PageLayout } from './components/PageLayout';
import { TimesheetComponent } from "./components/Timesheet/TimesheetComponent";
import { Verify } from "./components/Verify";
import { ReportsComponent } from "./components/Reports/ReportsComponent";
import { BillingReportComponent } from "./components/Reports/BillingReportComponent";
import { ClientBillingReportComponent } from "./components/Reports/ClientBillingReportComponent";
import { ClientTimesheetReportComponent } from "./components/Reports/ClientTimesheetReportComponent";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'scss/global/ConfirmAlert.scss';
import { UserEntityProvider } from 'common/UserEntityProvider'
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './components/authentication/context/authContext'
import { ToastMessageProvider } from "./components/ToastMessages/ToastMessageProvider";
import { ToastMessageQueue } from "./components/ToastMessages/ToastMessageQueue";
import { BrowserRouter } from 'react-router-dom';
import Providers from "./components/Provider/Providers";
import ProviderDetail from "./components/Provider/ProviderDetail";
import NewUser from "./components/User/NewUser";
import Roles from "./components/Roles/Roles";
import RoleDetail from "./components/Roles/RoleDetail";
import { ENTITY_TYPE_EMPLOYEE, ENTITY_TYPE_CLIENT } from "./common/constants";
import ProtectedRoute from "./components/ProtectedRoute";
import UserDetail from "./components/User/UserDetail";
import Users from "./components/User/Users";
import TimeOff from "./components/Time Off/TimeOff";
import TimeOffRequest from "./components/Time Off/TimeOffRequest";
import TimeOffDetail from "./components/Time Off/TimeOffDetail";
import HRCalendar from "./components/Time Off/HRCalendar";

export default function App() {
  return (
    <BrowserRouter>
    <ToastMessageProvider>
    <AuthProvider>
    <ApiProvider>
    <UserEntityProvider>
    <PageLayout>
        <ToastMessageQueue autoDelete={true} autoDeleteTime={3000}></ToastMessageQueue>
          <AuthIsSignedIn>
            <Routes>
              <Route path="/" element={<DashboardComponent />} />
              <Route element={<ProtectedRoute entityType={ENTITY_TYPE_EMPLOYEE} />}>
                <Route path="/clients" element={<Clients />} />
                <Route path="/client/new" element={<ClientDetail />} />
                <Route path="/client/detail/:id" element={<ClientDetail />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/project/new" element={<ProjectDetail />} />
                <Route path="/project/detail/:idProject" element={<ProjectDetail />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/employee/new" element={<EmployeeDetail />} />
                <Route path="/employee/detail/:id" element={<EmployeeDetail />} />
              </Route>
              <Route path="/timesheet" element={<TimesheetComponent />} />
              <Route path="/reports/billing" element={<BillingReportComponent />} />
              <Route path="/reports/timesheet" element={<ReportsComponent />} />
              <Route element={<ProtectedRoute entityType={ENTITY_TYPE_CLIENT} />}>
                <Route path="/reports/billing/client" element={<ClientBillingReportComponent />} />
                <Route path="/reports/timesheet/client" element={<ClientTimesheetReportComponent />} />
              </Route>
              <Route element={<ProtectedRoute entityType={ENTITY_TYPE_EMPLOYEE} />}>
                <Route path="/providers" element={<Providers />} />
                <Route path="/provider/new" element={<ProviderDetail />} />
                <Route path="/provider/detail/:id" element={<ProviderDetail />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user/new" element={<NewUser />} />
                <Route path="/user/detail/:id" element={<UserDetail />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/role/new" element={<RoleDetail />} />
                <Route path="/role/detail/:id" element={<RoleDetail />} />
                <Route path="/timeOff" element={<TimeOff />} />
                <Route path="/timeOff/request" element={<TimeOffRequest />} />
                <Route path="/timeOffRequest/detail/:id" element={<TimeOffDetail />} />
                <Route path="/HRCalendar" element={<HRCalendar />} />
                <Route path="/HRCalendar/Holidays" element={<HRCalendar />} />
              </Route>
              <Route path="/reports/billing/client" element={<ClientBillingReportComponent />} />
              <Route path="/reports/timesheet/client" element={<ClientTimesheetReportComponent />} />
              <Route path="/providers" element={<Providers />} />
              <Route path="/provider/new" element={<ProviderDetail />} />
              <Route path="/provider/detail/:id" element={<ProviderDetail />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/new" element={<NewUser />} />
              <Route path="/user/detail/:id" element={<UserDetail />} />
              <Route path="/roles" element={<Roles />} />
              <Route path="/role/new" element={<RoleDetail />} />
              <Route path="/role/detail/:id" element={<RoleDetail />} />
              <Route path="/verify" element={<Verify />} />
            </Routes>
          </AuthIsSignedIn>
          <AuthIsNotSignedIn>
            <Routes>
              <Route path="/" element={<TimesheetComponent />} />
              <Route path="/verify" element={<Verify />} />
            </Routes>
          </AuthIsNotSignedIn>
    </PageLayout>
    </UserEntityProvider>
    </ApiProvider>
    </AuthProvider>
    </ToastMessageProvider> 
    </BrowserRouter>
  );
}