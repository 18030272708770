import React from 'react';
import { FaTrashAlt, FaEdit, FaCheck, FaTasks } from "react-icons/fa";

type TaskRowProp = {
    idTask: number,
    description: string,
    idJira: string,
    handleTaskEdit: Function,
    handleTaskDelete: Function,
    isTaskEditMode: Function
}

const TaskRow = ({ idTask, description, idJira, handleTaskEdit, handleTaskDelete, isTaskEditMode }: TaskRowProp) => {

    return (
        <>
            {
                description ?
                <tr className={isTaskEditMode(idTask) ? 'edit-mode' : ''}>
                    <td>{description}</td>
                    <td>{idJira}</td>
                    <td style={{ textAlign: 'center' }}><FaEdit title='Edit Task' className='mx-2 edit-icon' role="button" onClick={() => { handleTaskEdit(idTask) }} ></FaEdit><FaTrashAlt title='Delete Task' className='mx-2' role="button" onClick={() => { handleTaskDelete(idTask) }} ></FaTrashAlt></td>
                </tr> :
                null
            }
        </>
    )
}

export default TaskRow;