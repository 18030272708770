import React, {useContext} from "react";
import { useLocation } from 'react-router-dom';
import {MainNavigation} from "./MainNavigation.tsx";
import {Footer} from "./Footer.tsx";
import '../scss/App.scss';
import AuthProvider,  { AuthContext, AuthStatus, AuthIsSignedIn, AuthIsNotSignedIn } from './authentication/context/authContext'
import SignInButton from "./SignInButton";
import Spinner from './Spinner.tsx';
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const  PageLayout = (props) => {
    const { authStatus } = useContext(AuthContext)
    const isAuthenticated = authStatus === AuthStatus.SignedIn;
    const location = useLocation();
    const { hash, pathname, search } = location;
    return (
        <>
        <header className="sticky-top"><MainNavigation isAuthenticated={isAuthenticated}/></header>
             <AuthIsSignedIn>
                {props.children}
             </AuthIsSignedIn>
            <AuthIsNotSignedIn>
                <div className="signInBox">
                    {authStatus === AuthStatus.SignedOut && pathname.toLowerCase().indexOf('verify') < 0  &&<><img src="/biglogo.png" width={300}/> <div className="mb-3">Borderless Enterprise Resource Planning </div></>}
                    
                    <div className="mb-3"> {authStatus === AuthStatus.SignedOut && pathname.toLowerCase().indexOf('verify') < 0  && <>You are not signed in yet!</>}
                     {authStatus === AuthStatus.Loading  && <>Signing in</>}
                    </div>
                    {authStatus === AuthStatus.SignedOut && pathname.toLowerCase().indexOf('verify') >= 0  && <Spinner/>}
                    <div>
                        
                        {authStatus === AuthStatus.SignedOut && pathname.toLowerCase().indexOf('verify') < 0  && <SignInButton></SignInButton>}
                    </div>
                </div>
                {props.children}
            </AuthIsNotSignedIn>
        <Footer/>
        </>
    );
};