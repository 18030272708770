import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../../components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import TimeOffRequestForm from './TimeOffRequestForm';
import RequestedTimeOff from './RequestedTimeOff';
import '../../scss/Time Off/TimeOffRequest.scss';
import { addDays } from "date-fns";
import { DateRange } from 'react-day-picker';
import { formatDateTimeOff } from "../../common/utils";
import { getUserEntity } from 'common/UserEntityProvider';

export type TimeOffInput = {
    id: number | null,
    range: DateRange | undefined,
    title: string | null,
    idEmployee: number,
    employeeName: string | null,
    employeeLastName: string | null,
    idWorkflowState: number | null,
    workflowStateName: string | null,
    changeLog: string | null,
    idTimeOffRequestType: number | null,
    timeOffRequestTypeName: string | null,
	attachments?: any,
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: any
};

const TimeOffRequest = () => {

    const initialRange: DateRange = {
        from: new Date(),
        to: addDays(new Date(), 4)
    };

    const [input, setInput] = useState<TimeOffInput | null>({
        id: null,
        range: initialRange,
        title: "",
        idEmployee: null,
        employeeName: "",
        employeeLastName: "",
        idWorkflowState: null,
        workflowStateName: "",
        changeLog: "",
        idTimeOffRequestType: null,
        timeOffRequestTypeName: "",
        attachments: []
	});

    const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [requestType, setRequestType] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const userDetails = getUserEntity();

    const fetchRequests = async () => {
        setLoading(true);
        const response = await api.timeOffApi.apiVversionTimeOffSearchGet("1", userDetails.entityName, 1, 100, "employee", "asc", [], {}).then((response) => {
            if (response.data.data) {
                const formattedDates = response.data?.data?.queryResult?.map(req => {
                    return {
                        ...req,
                        from: formatDateTimeOff(req.from),
                        to: formatDateTimeOff(req.to),
                        
                    };
                });
                setSearchResult({
                    totalRecords: response.data.data.totalRecords,
                    queryResult: formattedDates
                });
                setInput({
                    ...input,
                    idEmployee: response.data?.data?.queryResult[0]?.idEmployee
                });
            } else {
                setSearchResult({
                    totalRecords: null,
                    queryResult: null
                });
            };
            setLoading(false);
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: error.message });
            setLoading(false);
        });
    };

    const fetchRequestType = async () => {
        const response = await api.timeOffRequestTypeApi.apiVversionTimeOffRequestTypeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setRequestType({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            } else {
                setRequestType({
                    totalRecords: null,
                    queryResult: null
                });
            };
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequestType'), body: error.message });
        });
    };

    useEffect(() => {
        fetchRequests();
        fetchRequestType();
	}, []);

    const handleCancel = () => {
        setInput({
            id: null,
            range: initialRange,
            title: "",
            idEmployee: null,
            employeeName: "",
            employeeLastName: "",
            idWorkflowState: null,
            workflowStateName: "",
            changeLog: "",
            idTimeOffRequestType: null,
            timeOffRequestTypeName: "",
            attachments: []
        });
        navigate('/timeOff');
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.range) {
            errors.range = intl.get('validate.errors.isRequired');
        }
        if (!input.idTimeOffRequestType) {
            errors.idTimeOffRequestType = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };
    
    const handleRequestChange = (e) => {
        if (e !== null) {
            setInput({...input, idTimeOffRequestType: e.id});
            setErrors(validate({
                ...input,
                idTimeOffRequestType: e.id
            }));
        } else {
            setInput({...input, idTimeOffRequestType: null});
        };
    };

    const handleDaySelect = (date: DateRange | undefined) => {
        if (date) {
            setInput({...input, range: date});
            setErrors(validate({
                ...input,
                range: date
            }));
        };
    };

    const handleSave = async (e) => {
        setLoadingSave(true);
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            let cmdJSON = {
                from: input.range.from,
                to: input.range.to,
                idEmployee: input.idEmployee,
                idTimeOffRequestType: input.idTimeOffRequestType,
            };
            const response = await api.timeOffApi.apiVversionTimeOffPostForm("1", JSON.stringify(cmdJSON), input.attachments, {}).then((response) => {
                if (response.data) {
                    toast.success({ body: intl.get('calendarEvent.toast.success.handleSave.add')});
                    setLoadingSave(false);
                };
            }).catch((error) => {
                if (error.response) {
                    console.log("Data :", error.response.data);
                    console.log("Status :" + error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toast.error({ header: intl.get('calendarEvent.toast.error.handleSave.add'), body: error.message });
                setLoadingSave(false);
            });
        }
    };

    return (
        <div className="container" style={{width: "900px"}}>
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('timeOff.request.header')}</h2>
                </div>
                <div className='card-body'>
                    <TimeOffRequestForm
                        requestType={requestType?.queryResult}
                        input={input}
                        setInput={setInput}
                        errors={errors}
                        handleCancel={handleCancel}
                        loadingSave={loadingSave}
                        handleRequestChange={handleRequestChange}
                        handleDaySelect={handleDaySelect}
                        handleSave={handleSave}
                    />
                    <div className='mt-20'>
                        <h4 className="timeOffTitle">{intl.get('timeOff.requested.header')}</h4>
                        {
                            loading === true ?
                            <Spinner /> :
                            <RequestedTimeOff timeOffData={searchResult}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeOffRequest;