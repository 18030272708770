import React, { createContext, useContext, useState } from 'react';
import { useApi } from 'api/ApiProvider';
import Api from 'axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { AuthContext, AuthStatus } from 'components/authentication/context/authContext';
import intl from 'react-intl-universal';
import { UserEntityDetails } from 'axiosApi/models';
let failures = 0;

export const UserEntityContext = createContext<UserEntityDetails>(null);

export const UserEntityProvider = ({ children }) => {
  const [inProgress, setInProgress] = useState(false);
  const api: Api = useApi();
  const authContext = useContext(AuthContext);
  
  const toast = useToastMessageQueue();

  let userEntityDetails = sessionStorage.getItem('UserEntity') ? JSON.parse(sessionStorage.getItem('UserEntity')) : null;
  //console.log(userEntityDetails);
  if (userEntityDetails == null && !inProgress && authContext != null && authContext.authStatus === AuthStatus.SignedIn && failures<3) {
    if (!inProgress) {
      setInProgress(true);
      try {
        api.loginApi.apiVversionLoginEntitiesGet("1", sessionStorage.getItem('username')).then(r => {
          if (r.data.data && r.data.data.length > 0 && !!r.data.data[0]) {
            console.log("Grabbed data");
            userEntityDetails = r.data.data[0];
            sessionStorage.setItem('UserEntity', JSON.stringify(userEntityDetails));
          } else {
            sessionStorage.removeItem('UserEntity');
            toast.error({ header: 'Error', body: intl.get('employeeProvider.toast.error') /*r.data.errors*/ });
            authContext.signOut();
            window.location.href = "/";
          }
        }).catch((error) => {
          toast.error({ header: `Error: ${intl.get('employeeProvider.toast.error')}`, body: error });
          failures++;
        }).finally(() => {
          setInProgress(false);
        });
      }
      catch (error) {
        //toast.error({ header: 'Error: User login fail!', body: error });
        setInProgress(false);
        authContext.signOut();
        window.location.href = "/";
      }
    }
  }
  if(failures == 3)
  {
    setTimeout(()=>{failures = 0;}, 1000*60*1)
  }

  return <UserEntityContext.Provider value={userEntityDetails} >
    {children}
  </UserEntityContext.Provider >
}

export function getUserEntity() {
  return useContext(UserEntityContext);
}
