import React, { FC, useEffect } from 'react';
import Select from 'react-select';
import listItems from '../fixture/listItems';
import '../scss/ItemSelect.scss';

type ItemsSelectProps = {
  input: object,
  setInput: Function,
  limit: number,
  setLimit: (limit: number) => void,
  storageKey: string
};

const ItemsSelect: FC<ItemsSelectProps> = ({ input, setInput, limit, setLimit, storageKey }) => {
  
  const handleSelectChange = (selectedOption) => {
    const selectedValue = selectedOption.id;
    setInput({ ...input, currentPage: 1 });
    setLimit(selectedValue);
    localStorage.setItem(storageKey, selectedValue.toString());
  };

  useEffect(() => {
    const storedLimit = parseInt(localStorage.getItem(storageKey), 10);
    if (storedLimit) {
      setLimit(storedLimit);
    }
  }, [storageKey, setLimit]);

  const selectedOption = listItems.find(item => item.id === limit);

  return (
    <Select
      id="listItems"
      name="listItems"
      getOptionLabel={e => e.name}
      getOptionValue={e => e.id.toString()}
      value={selectedOption}
      onChange={handleSelectChange}
      options={listItems}
      className='item-select'
      menuPlacement="top"
    />
  );
};

export default ItemsSelect;
