import React from 'react';
import { Link } from 'react-router-dom';
import EmployeeSearchBar from './EmployeeSearchBar';
import '../../scss/Navbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'react-intl-universal';

const EmployeeNavbar = ({ onSearch }) => {
    
    return (
        <nav className='navbar container-fluid'>
            <EmployeeSearchBar onSearch={onSearch} />
            <Link to= '/employee/new'><button className='btn btn-primary p-1 plusButton' type='button' id='newEmployee' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
            <ReactTooltip 
                id='tooltipButton'
                place='left'
                content={intl.get('employeeNavbar.newEmployee.button')}
            />
        </nav> 
    )
}

export default EmployeeNavbar;