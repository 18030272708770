import React, { FC } from 'react';
import Select from 'react-select';
import { Client } from 'axiosApi/models'
import 'scss/Project/ClientSelect.scss';
import intl from 'react-intl-universal';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';

type ClientSelectProps = {
  handleClientChange: any,
  selectedClient: any,
  clients: any,
  disabled: any,
  newUser: boolean,
  id: any,
  securityRoles: any,
  edit: boolean,
  setHiddenRole: any,
};

const ClientSelect: FC<ClientSelectProps> = ({ handleClientChange, clients, selectedClient, disabled, newUser, id, securityRoles, edit, setHiddenRole }) => {
  
  const clientsSortedByName = sortCollection(clients, "name", SORT_TYPES.ASC);

  const customStyles = {
    control: base => ({
      ...base,
      height: 56,
      minHeight: 56
    }),
    menu: (base) => ({
      ...base,
      maxHeight: '18.7rem',
    })
  };

  const handleChange = (selectedOption: any) => {
    handleClientChange(selectedOption, id);
  };

  const handleMenuOpen = () => {
    setHiddenRole(true);
  };

  const handleMenuClose = () => {
    setHiddenRole(false);
  };

  return (
    <Select
      id='idClient'
      name='idClient'
      getOptionLabel={e => e.name}
      getOptionValue={e => e.id}
      placeholder={intl.get('projectForm.placeholder.client')}
      value={clients != null && selectedClient != null ? (clients?.find((client: Client) => client.id === selectedClient)) : ''}
      onChange={handleChange}
      isLoading={clients == null}
      options={clientsSortedByName}
      isDisabled={disabled}
      styles={newUser ? customStyles : undefined}
      onMenuOpen={ setHiddenRole ? handleMenuOpen : null}
      onMenuClose={ setHiddenRole ? handleMenuClose : null}
    />
  );
}

export default ClientSelect;