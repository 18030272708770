import React from "react";
import ContactInfo from './ContactInfo';
import Modal, { ModalSizes } from "./Modal";

export const ModalContactInfo = ({ contactInfoId, contactInfos, setContactInfos, contactInfosId, setContactInfosId, isOpen, closeModal, loading, setLoading }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            {/* <ContactInfo closeModal={closeModal} contactInfoId={contactInfoId} contactInfos={contactInfos} setContactInfos={setContactInfos} contactInfosId={contactInfosId} setContactInfosId={setContactInfosId} loading={loading} setLoading={setLoading}/> */}
            <ContactInfo closeModal={closeModal} contactInfoId={contactInfoId} contactInfos={contactInfos} setContactInfos={setContactInfos} contactInfosId={contactInfosId} setContactInfosId={setContactInfosId}/>
        </Modal>
    )
}

export default ModalContactInfo;