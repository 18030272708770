import React, {useEffect} from "react";
import ProjectEmployeeRow from "./ProjectEmployeeRow";
import { formatDate } from "common/utils";
import intl from 'react-intl-universal';

const ProjectEmployeeList = ({ handleEmployeeEdit, handleEmployeeDelete, isEmployeeEditMode, disabled, input, setInput, inactiveEmployees, setInactiveEmployees, employeesByProjectActivePaginated, employeesByProjectPaginated }) => {

    const handleInactiveEmployees = () => {
        setInactiveEmployees(!inactiveEmployees);
        setInput({ ...input, currentPage: 1 });
    };

    return (
        <div className="container-overflow">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">{intl.get('employeeList.table.thead.employee')}</th>
                        <th scope="col" style={{ textAlign: 'center' }}>{intl.get('employeeList.table.thead.hourlyRate')}</th>
                        <th scope="col" style={{ textAlign: 'center' }}>{intl.get('employeeList.table.thead.monthlyRate')}</th>
                        <th scope="col">{intl.get('employeeList.table.thead.allocation')}</th>
                        <th scope="col">{intl.get('employeeList.table.thead.role')}</th>
                        <th scope="col" style={{ textAlign: 'center' }}>Timesheet Viewer</th>
                        <th scope="col">{intl.get('employeeList.table.thead.startDate')} {intl.get('employeeList.table.thead.dueDate')} </th>
                        <th scope="col" style={{ textAlign: 'center' }}>{intl.get('employeeList.table.thead.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        inactiveEmployees ?
                        employeesByProjectPaginated.map((employee,i) => (
                            <ProjectEmployeeRow
                                key={employee.id}
                                name={employee.employee.name}
                                lastName={employee.employee.lastName}
                                hourlyRate={employee.hourlyRate}
                                monthlyRate={employee.monthlyRate}
                                role={employee.role.name}
                                startDate={employee.startDate ? formatDate(new Date(employee.startDate)) : ''}
                                dueDate={employee.dueDate ? formatDate(new Date(employee.dueDate)) : ''}
                                timeSheetViewer={employee.isTimesheetViewer}
                                handleEmployeeEdit={handleEmployeeEdit}
                                idEmployee={employee.id}
                                handleEmployeeDelete={handleEmployeeDelete}
                                isEmployeeEditMode={isEmployeeEditMode}
                                disabled={disabled}
                                input={input}
                                className={i%2>0?'':'odd'}
                                allocation={employee.allocation}
                                
                                allocationType={employee.allocationType}
                            />
                        )) :
                        employeesByProjectActivePaginated.map((employee,i) => (
                            <ProjectEmployeeRow
                                key={employee.id}
                                name={employee.employee.name}
                                lastName={employee.employee.lastName}
                                hourlyRate={employee.hourlyRate}
                                monthlyRate={employee.monthlyRate}
                                role={employee.role.name}
                                startDate={employee.startDate ? formatDate(new Date(employee.startDate)) : ''}
                                dueDate={employee.dueDate ? formatDate(new Date(employee.dueDate)) : ''}
                                timeSheetViewer={employee.isTimesheetViewer}
                                handleEmployeeEdit={handleEmployeeEdit}
                                idEmployee={employee.id}
                                handleEmployeeDelete={handleEmployeeDelete}
                                isEmployeeEditMode={isEmployeeEditMode}
                                disabled={disabled}
                                input={input}
                                className={i%2>0?'':'odd'}
                                allocation={employee.allocation}
                                
                                allocationType={employee.allocationType}
                            />
                        ))
                    }
                </tbody>
            </table>
            <div className="form-check ms-1">
                <input className="form-check-input" type="checkbox" value="" onClick={handleInactiveEmployees}/>
                <label className="form-check-label">{intl.get('employeeList.checkbox.inactive')}</label>
            </div>
        </div>
    )
}

export default ProjectEmployeeList;