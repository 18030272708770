/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Workflow API
 * Workflows
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { RequestArgs, BaseAPI, RequiredError } from '../base';
import { StringResponse } from '../models';
import { WorkflowSearchResponseResponse } from '../models/workflow-search-response-response';
import { WorkflowStateDTOSearchResponseResponse } from '../models/workflow-state-dtosearch-response-response';
import { WorkflowStateSearchResponseResponse } from '../models/workflow-state-search-response-response';
import { Settings } from 'common/settings';
/**
 * WorkflowApi - axios parameter creator
 * @export
 */
const BASE_PATH = Settings.getInstance().BASE_API_URL_WORKFLOW;
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowAllGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowAllGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdGet: async (version: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionWorkflowIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdInitialGet: async (version: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionWorkflowIdInitialGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdInitialGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/{id}/initial`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdModuleGet: async (version: string, idModule?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdModuleGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/idModule`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (idModule !== undefined) {
                localVarQueryParameter['idModule'] = idModule;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdStateIdStateGet: async (version: string, idState: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idState' is not null or undefined
            if (idState === null || idState === undefined) {
                throw new RequiredError('idState','Required parameter idState was null or undefined when calling apiVversionWorkflowIdStateIdStateGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowIdStateIdStateGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/idState/{idState}`
                .replace(`{${"idState"}}`, encodeURIComponent(String(idState)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowStatesByModuleNameModuleNameGet: async (version: string, moduleName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleName' is not null or undefined
            if (moduleName === null || moduleName === undefined) {
                throw new RequiredError('moduleName','Required parameter moduleName was null or undefined when calling apiVversionWorkflowStatesByModuleNameModuleNameGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionWorkflowStatesByModuleNameModuleNameGet.');
            }
            const localVarPath = `/api/v{version}/Workflow/statesByModuleName/{moduleName}`
                .replace(`{${"moduleName"}}`, encodeURIComponent(String(moduleName)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdGet(version: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdGet(version, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdInitialGet(version: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdInitialGet(version, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdModuleGet(version, idModule, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdStateIdStateGet(version: string, idState: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowIdStateIdStateGet(version, idState, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowStatesByModuleNameModuleNameGet(version: string, moduleName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WorkflowStateDTOSearchResponseResponse>>> {
            const localVarAxiosArgs = await WorkflowApiAxiosParamCreator(configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(version, moduleName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdGet(version: string, id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdGet(version, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdInitialGet(version: string, id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdInitialGet(version, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdModuleGet(version, idModule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdStateIdStateGet(version: string, idState: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowIdStateIdStateGet(version, idState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowStatesByModuleNameModuleNameGet(version: string, moduleName: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WorkflowStateDTOSearchResponseResponse>> {
            return WorkflowApiFp(configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(version, moduleName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI {
    /**
     * 
     * @summary Gets all the items in a list.
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdGet(version: string, id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdGet(version, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the inital state for a worfklow
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdInitialGet(version: string, id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdInitialGet(version, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all the workflows for a given module Id.
     * @param {string} version 
     * @param {number} [idModule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdModuleGet(version, idModule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the possible states given the current state
     * @param {number} idState 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowIdStateIdStateGet(version: string, idState: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdStateIdStateGet(version, idState, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the states for a module by module name.
     * @param {string} moduleName 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public async apiVversionWorkflowStatesByModuleNameModuleNameGet(version: string, moduleName: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WorkflowStateDTOSearchResponseResponse>> {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(version, moduleName, options).then((request) => request(this.axios, this.basePath));
    }
}