import { getUserEntity } from "common/UserEntityProvider";
import { ENTITY_TYPE_ANY } from "common/constants";
import React, { useState } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { hasPermission, hasRole } from "./hasPermission";



export default function ProtectedRoute({viewScopes=[], viewRoles=[], entityType=1, redirectTo = "/", children }) {
    const [TokenRoles, setTokenRoles] = useState([]);
const viewGranted = (hasPermission({ scopes:viewScopes }) || hasRole({ userRoles:TokenRoles, roles:viewRoles})) || (entityType == getUserEntity()?.entityType || entityType ==ENTITY_TYPE_ANY);
 if (!viewGranted) {
  return <Navigate to={redirectTo} />
 }
 return children ? children : <Outlet />
}