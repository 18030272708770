const filtersClients = {
    "filters": [
      {
        "description": "Status",
        "value": "status",
        "filtersItems": [
          {
            "description": "Active",
            "value": "Active"            
          },
          {
            "description": "Inactive",
            "value": "Inactive"
          },
          {
            "description": "Parcial",
            "value": "Parcial"
          }
        ]
      },
      {
        "description": "Category",
        "value": "category",
        "filtersItems": [
          {
            "description": "Cliente Internacional",
            "value": "International"
          },
          {
            "description": "Sociedad Anonima",
            "value": "S.A."
          }
        ]
      }
    ]
}

export default filtersClients;