import React from 'react';
import { Form, Col, FormGroup, Label, Input, Row } from 'reactstrap';
import ContactTypeSelect from './ContactTypeSelect';
import '../scss/ContactForm.scss';
import intl from 'react-intl-universal';

const ContactForm = ({ errors, input, handleInputChange, contactTypes, selectedContactTypes, handleContactTypeChange }) => {
    
    return (
        <Form>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="Name" className={`${errors.contactName ? 'text-danger' : ''}`}>
                            {intl.get('contactForm.name')} {errors.contactName && (<span className='text-danger'>{errors.contactName}</span>)}
                        </Label>
                        <Input
                            id="Name"
                            name="contactName"
                            placeholder={intl.get('contactForm.placeholder.name')}
                            value={input.contactName}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="Email" className={`${errors.emailAddress ? 'text-danger' : ''}`}>
                            {intl.get('contactForm.email')} {errors.emailAddress && (<span className='text-danger'>{errors.emailAddress}</span>)}
                        </Label>
                        <Input
                            id="Email"
                            name="emailAddress"
                            placeholder={intl.get('contactForm.placeholder.email')}
                            value={input.emailAddress}
                            onChange={handleInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="Phone1">{intl.get('contactForm.phone1')}</Label>
                        <Input
                            id="Phone 1"
                            name="phone1"
                            type="tel"
                            placeholder={intl.get('contactForm.placeholder.phone1')}
                            value={input.phone1}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="Phone2">{intl.get('contactForm.phone2')}</Label>
                        <Input
                            id="Phone 2"
                            name="phone2"
                            type="tel"
                            placeholder={intl.get('contactForm.placeholder.phone2')}
                            value={input.phone2}
                            onChange={handleInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="role" className={`${errors.role ? 'text-danger' : ''}`}>
                            {intl.get('contactForm.role')} {errors.role && (<span className='text-danger'>{errors.role}</span>)}
                        </Label>
                        <Input
                            id="Role"
                            name="role"
                            placeholder={intl.get('contactForm.placeholder.role')}
                            value={input.role}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="contactType" className={`${errors.idContactType ? 'text-danger' : ''}`}>
                            {intl.get('contactForm.contactType')} {errors.idContactType && (<span className='text-danger'>{errors.idContactType}</span>)}
                        </Label>
                        <ContactTypeSelect
                            contactTypes={contactTypes}
                            selectedContactTypes={selectedContactTypes}
                            handleContactTypeChange={handleContactTypeChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )
}

export default ContactForm;