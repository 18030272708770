import React from 'react';
import { Link } from 'react-router-dom';
import UserSearchBar from './UserSearchBar';
import '../../scss/User/UserNavbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'react-intl-universal';

const UserNavbar = ({ onSearch }) => {
    
    return (
        <nav className='navbar container-fluid'>
            <UserSearchBar onSearch={onSearch}/>
            <Link to= '/user/new'><button className='btn btn-primary p-1 plusButton' type='button' id='newUser' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
            <ReactTooltip 
                id='tooltipButton'
                place='left'
                content={intl.get('navbar.newUser.button')}
            />
        </nav>
    )
}

export default UserNavbar;