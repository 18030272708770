import React,{useContext} from "react";
import { HiOutlineLogout } from "react-icons/hi";
import AuthProvider,  { AuthContext, AuthStatus, AuthIsSignedIn, AuthIsNotSignedIn } from './authentication/context/authContext'
import intl from 'react-intl-universal';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignOutButton = () => {

    const authContext = useContext(AuthContext);
    
    const handleLogout = async () => {
        authContext.signOut();
    }

    return (
        <>
            <HiOutlineLogout className="mb-1"></HiOutlineLogout>
            <a className="ms-1 user-options" href="#" onClick={handleLogout}>
                <span>{intl.get('singOutButton')}</span>
            </a>
        </>
    )
}