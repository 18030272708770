const listItems = [
    {
        id: 5,
        name: '5'
    },
    {
        id: 10,
        name: '10'
    },
    {
        id: 20,
        name: '20'
    },
    {
        id: 50,
        name: '50'
    },
    {
        id: 100,
        name: '100'
    },
];

export default listItems;