const filtersProject = {
  "filters": [
    {
      "description": "Status",
      "value": "status",
      "filtersItems": [
        {
          "description": "Active",
          "value": "Active"            
        },
        {
          "description": "Inactive",
          "value": "Inactive"
        },
        {
          "description": "Parcial",
          "value": "Parcial"
        }
      ]
    },
    {
      "description": "Category",
      "value": "category",
      "filtersItems": [
        {
          "description": "Proyecto Externo",
          "value": "Externo"
        },
        {
          "description": "Proyecto Interno",
          "value": "Interno"
        }
      ]
    }
  ]
}

export default filtersProject;