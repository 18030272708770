import React from 'react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';

const ClientRow = ({ id, name, tradeName, className }) => {

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/client/detail/' + id);
    };

    return (
        <>
            {
                name ?
                <tr className={className}>
                    <td>{name}</td>
                    <td>{tradeName}</td>
                    <td className='text-center'>
                        <a className='td-icon mx-1' onClick={handleClickRow} title={intl.get('roleRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                    </td>
                </tr> :
                null
            }
        </>
    )   
}

export default ClientRow;