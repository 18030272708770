import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateUserCommand } from 'axiosApi/models';
import {PermissionsGate } from '../../components/PermissionsGate';
import { SCOPES, ROLES } from "../../common/permissions"; 
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import { Form, Row, Col, Label, Input } from 'reactstrap';
import {USER_STATUS} from '../../common/constants';
import { Employee, Role, Client } from 'axiosApi/models';
import { HiArrowLeft, HiOutlineSave, HiPlusCircle, HiOutlinePlusCircle } from 'react-icons/hi';
import '../../scss/User/NewUser.scss';
import StatusSelect from '../StatusSelect';
import RequiredField from 'components/RequiredField';
import ModalNewEmployee from './ModalNewEmployee';
import UserAsEmployee from './UserAsEmployee';
import UserAsClient from './UserAsClient';
import UserAsProvider from './UserAsProvider';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../Spinner';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { useToastHelper } from 'common/utils';

const NewUser = () => {

    const generateUniqueId = () => uuidv4();

    const DefaultUserInput = {
        email: '',
        idStatus: null,
        userStatus: true,
        userDetails: [
            {
                idFe: generateUniqueId(),
                id: 0,
                entityType: 1,
                entityId: 0,
                securityRoles: []
            },
            {
                idFe: generateUniqueId(),
                id: 0,
                entityType: 2,
                entityId: 0,
                securityRoles: []
            },
            {
                idFe: generateUniqueId(),
                id: 0,
                entityType: 3,
                entityId: 0,
                securityRoles: []
            }
        ]
    };

    const api: Api = useApi();

    const toast = useToastMessageQueue();
    const toastHelper = useToastHelper(toast)

    const [input, setInput] = useState(DefaultUserInput);

    const [employees, setEmployees] = useState<Employee[]>(null);

    const [roles, setRoles] = useState<Role[]>(null);

    const [clients, setClients] = useState<Client[]>(null);

    const [providers, setProviders] = useState(null);

    const [disabled, setDisabled] = useState(false);

    const [isNewEmployeeOpen, setIsNewEmployeeOpen] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({email: null});

    const [addEmployeeActive, setAddEmployeeActive] = useState(true);

    const [addClientActive, setAddClientActive] = useState(true);

    const [addProviderActive, setAddProviderActive] = useState(true);

    const [hiddenRole, setHiddenRole] = useState(false);

    const navigate = useNavigate();

    const fetchEmployees = async () => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionEmployeeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setEmployees(response.data?.data?.queryResult);
            };
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('employee.toast.error.fetchEmployees'), body: error.message });
            setLoading(false);
        });
    };

    const fetchRoles = async () => {
        setLoading(true);
        const response = await api.securityRoleApi.apiVversionSecurityRoleAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setRoles(response.data?.data?.queryResult);
            };
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('employeeProject.toast.error.fetchRoles'), body: error.message });
            setLoading(false);
        });
    };

    const fetchClients = async () => {
        setLoading(true);
        const response = await api.clientApi.apiVversionClientAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setClients(response.data.data.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('projectDetail.toast.error.fetchClients'), body: error.message });
            setLoading(false);
        });
    };

    const fetchProviders = async () => {
        setLoading(true);
        const response = await api.providerApi.apiVversionProviderAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setProviders(response.data?.data?.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('newUser.toast.error.fetchProviders'), body: error.message });
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchEmployees();
        fetchRoles();
        fetchClients();
        fetchProviders();
        setDisabled(true);
    }, []);

    useEffect(() => {
        if (input.email) {
            const newErrors = validate(input);
            setErrors(newErrors);
        };
        validateAddEntity(input);
    }, [input]);

    const handleEmailChange = (e) => {
        setInput({
            ...input,
            email: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleUserStatusChange = (e) => {
        const inputVal = {
            ...input,
            idStatus: e.id,
            userStatus: e.active
        };
        setInput({ ...inputVal });
    };

    const handleUserChange = (selected, id) => {
        const updatedUserDetails = input.userDetails.map(user => 
            user.idFe === id 
            ? { ...user, entityId: selected.id } 
            : user
        );
        setInput((prevInput) => ({
            ...prevInput,
            userDetails: updatedUserDetails
        }));
        setErrors(validate(input));
    };

    const handleRolesUserChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        const securityRolesNames = event.map(role => role.name);
        const updatedUserDetails = input.userDetails.map(user => 
            user.idFe === id 
            ? { ...user, securityRoles: selectedRolesIds } 
            : user
        );
        setInput((prevInput) => ({
            ...prevInput,
            securityRoles: securityRolesNames,
            userDetails: updatedUserDetails
        }));
    };

    const addUser = (entityType: number) => {
        setInput((prevInput) => ({
            ...prevInput,
            employee: { name: '', lastName: '' },
            idEmployee: null,
            idSecurityRoles: [],
            userDetails: [
                ...prevInput.userDetails,
                {
                    idFe: generateUniqueId(),
                    id: 0,
                    entityType: entityType,
                    entityId: 0,
                    securityRoles: []
                }
            ]
        }));
    };

    const handleNewEmployeeOpen = () => {
        setIsNewEmployeeOpen(true);
    };

    const closeNewEmployee = () => setIsNewEmployeeOpen(false);

    const validate = function (input) {
        setDisabled(false);
        let errors: any = {email: null, userWithoutEntity: null};
        if (!input.email) {
            errors.email = intl.get('validate.errors.isRequired');
            setDisabled(true);
        } else if (!/\S+@\S+\.\S+/.test(input.email)) {
            errors.email = intl.get('validate.errors.isInvalid');
            setDisabled(true);
        };
        const userWithoutEntity = input.userDetails.filter(user => user.entityId === 0 && user.securityRoles.length > 0);
        if (userWithoutEntity.length > 0) {
            errors.userWithoutEntity = intl.get('validate.errors.isRequired');
            setDisabled(true);
        };
        return errors;
    };

    const validateAddEntity = function (input) {
        const userEmployeeWithoutEntityAndRoles = input.userDetails.filter(user => user.entityType === 1 && user.entityId === 0 || user.entityType === 1 && user.securityRoles.length === 0);
        const userClientWithoutEntityAndRoles = input.userDetails.filter(user => user.entityType === 2 && user.entityId === 0 || user.entityType === 2 && user.securityRoles.length === 0);
        const userProvidertWithoutEntityAndRoles = input.userDetails.filter(user => user.entityType === 3 && user.entityId === 0 || user.entityType === 3 && user.securityRoles.length === 0);
        if (userEmployeeWithoutEntityAndRoles.length > 0) {
            setAddEmployeeActive(false);
        }else if (userEmployeeWithoutEntityAndRoles.length === 0)  {
            setAddEmployeeActive(true);
        };
        if (userClientWithoutEntityAndRoles.length > 0) {
            setAddClientActive(false);
        }else if (userClientWithoutEntityAndRoles.length === 0) {
            setAddClientActive(true);
        };
        if (userProvidertWithoutEntityAndRoles.length > 0) {
            setAddProviderActive(false);
        }else if (userProvidertWithoutEntityAndRoles.length === 0) {
            setAddProviderActive(true);
        };
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        const errors = validate(input);
        setErrors(errors);
        if (errors.email === null) {
            setLoading(true);
            const userDetailFilter = input.userDetails.filter(obj => obj.entityId !== 0);
            const userDetailsSave = userDetailFilter.map(user => {
                const { idFe, ...rest } = user;
                return rest;
            });
            let cmd: CreateUserCommand = {
                emailAddress: input.email,
                active: input.userStatus,
                userDetails: userDetailsSave
            };
            const response = await api.userApi.apiVversionUserPost("1", cmd, {}).then((response) => {
                toastHelper(response, 'newUser.toast.success.handleSave.add', 'newUser.toast.error.handleSave.add');
                setLoading(false);
                if (saveAndEdit && response?.data?.data?.id)
                    navigate(`/user/detail/${response.data.data.id}`);
                else
                    navigate('/user/new');
            }).catch((error) => {
                if (error.response) {
                    console.log("Data :", error.response.data);
                    console.log("Status :" + error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toastHelper(error, 'newUser.toast.success.handleSave.add', 'newUser.toast.error.handleSave.add');
                setLoading(false);
            });
        };
    };

    const handleBackClick = (e) => {
        navigate('/users');
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    return (
        <div className="container">
            <Row className='justify-content-center'>
                <div className='card mt-4 col-8'>
                    <div className="card-header">
                        <h2 className="title mb-0">{intl.get('newUser.header')}</h2>
                    </div>
                    <div className='card-body'>
                        <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.edit']]} viewRoles={null} editRoles={null} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
                            {
                                loading === true ?
                                <Spinner /> :
                                <Form>
                                    <Row form ="true">
                                        <div className='d-inline-flex col-12 flex-column'>
                                            <Label for="userEmail" className={`${errors.email !== "" && errors.email !== null ? 'text-danger' : ''}`}>
                                                {intl.get('newUser.email')} {errors.email !== "" && (<span className='text-danger'>{errors.email}</span>)}
                                            </Label>
                                            <Input
                                                id="userEmail"
                                                name="email"
                                                placeholder={intl.get('newUser.placeholder.email')}
                                                value={input?.email}
                                                onChange={handleEmailChange}
                                            />
                                        </div>
                                    </Row>
                                    <Row form ="true" className='mt-3 mb-4'>
                                        <div className='d-inline-flex col-12 flex-column'>
                                            <Label for="status">{intl.get('statusSelect.label')}</Label>
                                            <StatusSelect
                                                handleStatusChange={handleUserStatusChange}
                                                selectedStatus={input.idStatus}
                                                status={USER_STATUS}
                                                disabled={false}
                                                defaultValue={true}
                                                isClearable={false}
                                            />
                                        </div>
                                    </Row>
                                    <Row className='mb-2'>
                                        <span className='fw-bold mb-1'>{intl.get('newUser.assignment.title')}</span>
                                        <span>{intl.get('newUser.assignment.message')}</span>
                                    </Row>
                                    <Row>
                                        <Label for="userAsEmployee">
                                            {intl.get('newUser.userAsEmployee')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 1).map(comp => (
                                            <UserAsEmployee
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                securityRoles={comp.securityRoles}
                                                handleEmployeeChange={handleUserChange}
                                                employees={employees}
                                                input={input}
                                                setInput={setInput}
                                                handleRoleChange={handleRolesUserChange}
                                                roles={roles}
                                                hiddenRole={hiddenRole}
                                                setHiddenRole={setHiddenRole}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col md={6}>
                                            <HiPlusCircle className='me-1 newEmployeeColor'/>
                                            <a type='button' className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover newEmployeeSize" onClick={handleNewEmployeeOpen}>{intl.get('newUser.newEmployee')}</a>
                                            <ModalNewEmployee isOpen={isNewEmployeeOpen} closeModal={closeNewEmployee} setLoading={setLoading} employees={employees} setEmployees={setEmployees}/>
                                        </Col>
                                        <Col md={6} className={`${addEmployeeActive ? 'text-md-end addEmployeeActive' : 'text-md-end addEmployeeInactive'}`}>
                                            <HiOutlinePlusCircle className='me-1'/>
                                            <a type='button' className={`${addEmployeeActive ? "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover addEmployeeSize" : "link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover addEmployeeSize addUserEntityDisabled"}`} onClick={() => addUser(1)} >{intl.get('newUser.addEmployee')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator"></hr>
                                    <Row>
                                        <Label for="userAsClient">
                                            {intl.get('newUser.userAsClient')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 2).map(comp => (
                                            <UserAsClient
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                securityRoles={comp.securityRoles}
                                                handleClientChange={handleUserChange}
                                                clients={clients}
                                                input={input}
                                                setInput={setInput}
                                                handleRoleChange={handleRolesUserChange}
                                                roles={roles}
                                                hiddenRole={hiddenRole}
                                                setHiddenRole={setHiddenRole}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col md={12} className={`${addClientActive ? 'text-md-end addEmployeeActive' : 'text-md-end addEmployeeInactive'}`}>
                                            <HiOutlinePlusCircle className='me-1'/>
                                            <a type='button' className={`${addClientActive ? "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover addEmployeeSize" : "link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover addEmployeeSize addUserEntityDisabled"}`} onClick={() => addUser(2)}>{intl.get('newUser.addClient')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator"></hr>
                                    <Row>
                                        <Label for="userAsProvider">
                                            {intl.get('newUser.userAsProvider')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 3).map(comp => (
                                            <UserAsProvider
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                securityRoles={comp.securityRoles}
                                                handleProviderChange={handleUserChange}
                                                providers={providers}
                                                input={input}
                                                setInput={setInput}
                                                handleRoleChange={handleRolesUserChange}
                                                roles={roles}
                                                hiddenRole={hiddenRole}
                                                setHiddenRole={setHiddenRole}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col md={12} className={`${addProviderActive ? 'text-md-end addEmployeeActive' : 'text-md-end addEmployeeInactive'}`}>
                                            <HiOutlinePlusCircle className='me-1'/>
                                            <a type='button' className={`${addProviderActive ? "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover addEmployeeSize" : "link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover addEmployeeSize addUserEntityDisabled"}`} onClick={() => addUser(3)}>{intl.get('newUser.addProvider')}</a>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={4} className='mb-2 mb-md-0'>
                                            <RequiredField/>
                                        </Col>
                                        <Col md={8} className='d-flex justify-content-end my-3'>
                                            <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                                <HiArrowLeft/>
                                            </button>
                                            <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.edit']]} viewRoles={[]} editRoles={[]} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
                                                <ButtonWithOptions disabled={disabled} options={[{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, type: ButtonWithOptionsType.item }]} outline={true} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                            </PermissionsGate> 
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        </PermissionsGate>
                    </div>
                </div>
            </Row>
		</div>
    )
}

export default NewUser;