import React from "react";
import '../scss/Modal.scss';

export enum ModalSizes {
    small = 'small',
    default = 'default',
    medium = 'medium',
    large = 'large'

}

export default function Modal({ children, isOpen, closeModal, height = '', size = 'default' }) {
    return (
        <article className={`modal ${isOpen && "is-open"}${" modal-" + size}${height != '' && (" modal-height-" + height)}`} id="switchProfile">
            <div className="modal-container card">
                <button type="button" className="btn-close ms-auto me-0 d-block" aria-label="Close" onClick={closeModal} ></button>
                {children}
            </div>
        </article>
    )
}
