import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { HiOutlinePlus } from 'react-icons/hi';
import { FaSearch } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CountrySelect from '../../components/CountrySelect';
import ModalCalendar from './ModalCalendar';
import RequestSelect from './RequestSelect';
import TimeOffSearchBar from './TimeOffSearchBar';


const HRCalendarNavbar = ({ handleCountryChange, selectedCountry, countries, input, handleCalendarEventDetailOpen, isCalendarEventDetailOpen, closeCalendarEventDetail, nonWorkingDay, requestType, onSearch, updateEvents }) => {

    const [idRequest, setIdRequest] = useState(null);

    const [queryString, setQueryString] = useState<string>('');

    const handleSearchButton = () => {
        onSearch(queryString, idRequest);
    };

    const handleRequestChange = (e) => {
        if (e !== null) {
            setIdRequest(e.id);
        } else {
            setIdRequest(null);
        }
    };

    return (
        <nav className={nonWorkingDay ? 'navbar container-fluid mb-5' : 'navbar container-fluid filters gap-3 mb-5'}>
            {
                nonWorkingDay ?
                <>
                    <CountrySelect 
                        handleCountryChange={handleCountryChange}
                        selectedCountry={selectedCountry}
                        countries={countries}
                        inputCountry={input.country.name}
                        isClearable={true}
                    />
                    <button className='btn btn-primary p-1 plusButton' type='button' id='addHoliday' data-tooltip-id='tooltipButton' onClick={handleCalendarEventDetailOpen} ><HiOutlinePlus className='plusIcon'/></button>
                    <ReactTooltip 
                        id='tooltipButton'
                        place='left'
                        content={intl.get('timeOffCalendarNavbar.addHoliday.button')}
                    />
                    <ModalCalendar isOpen={isCalendarEventDetailOpen} closeModal={closeCalendarEventDetail} eventId={null} updateEvents={updateEvents}/>
                </>
                :
                <>
                    <TimeOffSearchBar queryString={queryString} setQueryString={setQueryString}/>
                    <RequestSelect requestType={requestType} selectedRequest={idRequest} handleRequestChange={handleRequestChange} />
                    <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;{intl.get('jiraIssueSearch.search.button')}</button>
                </>
            }
        </nav>
    )
}

export default HRCalendarNavbar;