import React from "react";
import Address from "./Address";
import Modal, { ModalSizes } from "./Modal";

const ModalAddress = ({ addressId, setAddresses, addresses, addressesId, setAddressesId, isOpen, closeModal, inputProvider, setInputProvider }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <Address closeModal={closeModal} addressId={addressId} addresses={addresses} setAddresses={setAddresses} addressesId={addressesId} setAddressesId={setAddressesId} inputProvider={inputProvider} setInputProvider={setInputProvider} />
        </Modal>
    )
}

export default ModalAddress;