import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import { UpdateTimeOffCommand } from '../../axiosApi/models/update-time-off-command';
import intl from 'react-intl-universal';
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import "../../scss/Time Off/TimeOffApprove.scss";
import Chip from '@mui/material/Chip';
import { HiOutlineCalendar, HiOutlineSave, HiOutlineDownload } from "react-icons/hi";
import Spinner from '../Spinner';
import RequestStatusSelect from './RequestStatusSelect';
import StyledDropzone from "./TimeOffFileUploader";

const TimeOffDetail = () => {

    const DefaultApproveInput = {
        changeLog: '',
        employeeName: '',
        employeeLastName: '',
        from: '',
        id: 0,
        idEmployee: 0,
        idTimeOffRequestType: 0,
        idWorkflowState: 0,
        timeOffRequestTypeName: '',
        title: '',
        to: '',
        workflowStateName: '',
        description: '',
        attachments: []
    };

    const [input, setInput] = useState(DefaultApproveInput);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const { id } = useParams();
    const idNumber = id && parseInt(id);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const [requestStatus, setRequestStatus] = useState(null);

    const color = input.workflowStateName === "Pending" || input.workflowStateName === "Pendiente" ? "warning" : input.workflowStateName === "Approved" || input.workflowStateName === "Aprobado" ? "success" : input.workflowStateName === "Observed" || input.workflowStateName === "Observado" ? "default" : input.workflowStateName === "Open" || input.workflowStateName === "Abierto" ? "primary" : input.workflowStateName === "Reopen" || input.workflowStateName === "Reabrir" ? "secondary" : "error";

    const formatDate = (dateString) => {
        const fechaDate = new Date(dateString);
        const day = String(fechaDate.getDate()).padStart(2, '0');
        const month = String(fechaDate.getMonth() + 1).padStart(2, '0');
        const year = fechaDate.getFullYear();
        return `${day}/${month}/${year}`;
    };

    function formatDateISO(dateString) {
        const [day, month, year] = dateString.split('/');
        const dateISO = new Date(`${year}-${month}-${day}T00:00:00`);
        return dateISO;
    };

    const fetchTimeOffApprove = async (idNumber: number) => {
        setLoading(true);
        const response = await api.timeOffApi.apiVversionTimeOffIdGet(idNumber, "1", {}).then((response) => {
            if (response.data) {
                setInput({
                    ...input,
                    changeLog: response.data?.data?.changeLog,
                    employeeName: response.data?.data?.employeeName,
                    employeeLastName: response.data?.data?.employeeLastName,
                    from: formatDate(response.data?.data?.from),
                    id: response.data?.data?.id,
                    idEmployee: response.data?.data?.idEmployee,
                    idTimeOffRequestType: response.data?.data?.idTimeOffRequestType,
                    idWorkflowState: response.data?.data?.idWorkflowState,
                    timeOffRequestTypeName: response.data?.data?.timeOffRequestTypeName,
                    title: response.data?.data?.title,
                    to: formatDate(response.data?.data?.to),
                    workflowStateName: response.data?.data?.workflowStateName,
                });
                console.log("Success");
                setLoading(false);
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOffApprove.toast.error.fetchTimeOffApprove'), body: error.message });
            setLoading(false);
        });
    };

    const fetchRequestStatus = async () => {
        const response = await api.workflowApi.apiVversionWorkflowStatesByModuleNameModuleNameGet("1", "TimeOff", {}).then((response) => {
            if (response.data.data) {
                setRequestStatus({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            console.log("Success");
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequestStatus'), body: error.message });
        });
    };

    useEffect(() => {
        if (idNumber) {
            fetchTimeOffApprove(idNumber);
        }
        fetchRequestStatus();
	}, [idNumber]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    };

    const handleCancel = () => {
        setInput(DefaultApproveInput);
        navigate('/timeOff');
    };

    const handleStatusChange = (e) => {
        if (e !== null) {
            setInput({
                ...input,
                workflowStateName: e.name,
                idWorkflowState: e.id
            });
        } else {
            setInput({
                ...input,
                workflowStateName: '',
                idWorkflowState: 0
            });
        }
    };

    const handleSave = async (e) => {
        setLoadingSave(true);
        e.preventDefault();
        let cmdJSON: UpdateTimeOffCommand = {
            id: input.id,
            from: formatDateISO(input.from),
            to: formatDateISO(input.to),
            title: input.title,
            idEmployee: input.idEmployee,
            idWorkflowState: input.idWorkflowState,
            changeLog: input.changeLog,
            idTimeOffRequestType: input.idTimeOffRequestType,
        };
        let cmd = JSON.stringify(cmdJSON);
        const response = await api.timeOffApi.apiVversionTimeOffPutForm("1", cmd, input.attachments, [], {}).then((response) => {
            if (response.data) {
                toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
                setLoadingSave(false);
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('calendarEvent.toast.error.handleSave'), body: error.message });
            setLoadingSave(false);
        });
    };

    const handleCalendarView = () => {
        navigate('/HRCalendar');
    };

    return (
        <div className="container">
            <Row className='justify-content-center'>
                <div className='card mt-4 col-9'>
                    <div className="card-header">
                        <h2 className="title">{intl.get('timeOffApprove.header')}</h2>
                    </div>
                    <div className='card-body'>
                        {
                            loading === true ?
                            <Spinner /> :
                            <Form>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <Label for="employeeName">{intl.get('timeOffApprove.employeeName')}</Label>
                                            <Input
                                                id="employeeName"
                                                name="name"
                                                placeholder={intl.get('timeOffApprove.placeholder.name')}
                                                value={`${input.employeeName} ${input.employeeLastName}`}
                                                disabled={true}
                                                className={"input-name"}
                                            />
                                        </Col>
                                        <Col className='ms-2'>
                                            <Label for="status" className='d-block'>{intl.get('timeOffApprove.status.label')}</Label>
                                            <Chip label={input.workflowStateName} color={color} className={color === "default" ? 'observed' : ''}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <Label for="requestType">{intl.get('timeOffApprove.requestType.label')}</Label>
                                            <Input
                                                id="requestType"
                                                name="request"
                                                placeholder={intl.get('timeOffApprove.placeholder.requestType')}
                                                value={input?.timeOffRequestTypeName}
                                                disabled={true}
                                                className={"input-name"}
                                            />
                                        </Col>
                                        <Col className='ms-2'>
                                            <Label for="changeStatus" className='d-block'>{intl.get('timeOffApprove.changeStatus.label')}</Label>
                                            <RequestStatusSelect requestStatus={requestStatus?.queryResult} selectedStatus={input.idWorkflowState} handleStatusChange={handleStatusChange} isClearable={false} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col className='col-6'>
                                            <Label for="dates">{intl.get('timeOffApprove.dates.label')}</Label>
                                            <Input
                                                id="dates"
                                                name="dates"
                                                placeholder={intl.get('timeOffApprove.placeholder.dates')}
                                                value={`${input.from} - ${input.to}`}
                                                disabled={true}
                                                className={"input-name"}
                                            />
                                            <a type='button' className="link-secondary newEmployeeSize" onClick={handleCalendarView}>{intl.get('timeOff.calendar')}<HiOutlineCalendar className='ms-1 mb-1'/></a>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <hr className="separator my-4"></hr>
                                <FormGroup>
                                    <Row>
                                        <StyledDropzone
                                            files={input.attachments}
                                            setInput={setInput}
                                            disabled={true}    
                                        />
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col className='col-4'>
                                            <Label for="attachedFiles" className='d-block'>{intl.get('timeOffApprove.attachedFiles.label')}</Label>
                                            {
                                                input.attachments?.map((file, i) => (
                                                    <a type='button' className='text-decoration-underline d-block' key={i} href={file.path} download={file.path} >{file.path}<HiOutlineDownload className='ms-1 mb-1'/></a>
                                                ))
                                            }
                                        </Col>
                                        <Col className='col-8'>
                                            <Label for="description" className='d-flex text-start'>{intl.get('timeOffApprove.description')}</Label>
                                            <Input
                                                id="description"
                                                name="description"
                                                placeholder={intl.get('timeOffApprove.placeholder.description')}
                                                type="textarea"
                                                rows="5"
                                                value={input.description ? input.description : ''}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md={12} className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                                            {
                                                loadingSave === true ?
                                                <Spinner small={true} /> :
                                                <>
                                                    <button type="button" className="btn btn-outline-dark" onClick={handleCancel}>{intl.get('cancel.button')}</button>
                                                    <button type="submit" className="btn btn-primary" onClick={handleSave}><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</button>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Form>
                        }
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default TimeOffDetail;