/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Timesheet API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {  Settings } from "../../common/settings";
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateTimesheetCommand } from '../models';
import { GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse } from '../models';
import { StatusCodeResult } from '../models';
import { StringResponse } from '../models';
import { TimesheetByIdResponseResponse } from '../models';
import { TimesheetListResponseResponse } from '../models';
import { TimesheetResponse } from '../models';
import { UpdateTimesheetCommand } from '../models';
import { formatDateForAPI } from 'common/utils';
const BASE_PATH = Settings.getInstance().BASE_API_URL_TIMESHEET;

/**
 * TimesheetApi - axios parameter creator
 * @export
 */
export const TimesheetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of Timesheets by Date Range
         * @param {number} idEmployee 
         * @param {Date} fromDate 
         * @param {Date} toDate 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet: async (idEmployee: number, fromDate: Date, toDate: Date, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idEmployee' is not null or undefined
            if (idEmployee === null || idEmployee === undefined) {
                throw new RequiredError('idEmployee','Required parameter idEmployee was null or undefined when calling apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet.');
            }
            // verify required parameter 'fromDate' is not null or undefined
            if (fromDate === null || fromDate === undefined) {
                throw new RequiredError('fromDate','Required parameter fromDate was null or undefined when calling apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet.');
            }
            // verify required parameter 'toDate' is not null or undefined
            if (toDate === null || toDate === undefined) {
                throw new RequiredError('toDate','Required parameter toDate was null or undefined when calling apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet.');
            }
            const localVarPath = `/api/v{version}/Timesheet/getByEmployeeIdAndDateRange/{idEmployee}/{fromDate}/{toDate}`
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"fromDate"}}`, encodeURIComponent(formatDateForAPI(fromDate)))
                .replace(`{${"toDate"}}`, encodeURIComponent(formatDateForAPI(toDate)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of Timesheets by Job Id
         * @param {number} idJob 
         * @param {string} version 
         * @param {number} [offset] 
         * @param {number} [take] Limits the number of records returned. Default:              All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetByJobIdJobGet: async (idJob: number, version: string, offset?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idJob' is not null or undefined
            if (idJob === null || idJob === undefined) {
                throw new RequiredError('idJob','Required parameter idJob was null or undefined when calling apiVversionTimesheetGetByJobIdJobGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetGetByJobIdJobGet.');
            }
            const localVarPath = `/api/v{version}/Timesheet/getByJob/{idJob}`
                .replace(`{${"idJob"}}`, encodeURIComponent(String(idJob)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets last Timesheet by Employee id
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet: async (idEmployee: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idEmployee' is not null or undefined
            if (idEmployee === null || idEmployee === undefined) {
                throw new RequiredError('idEmployee','Required parameter idEmployee was null or undefined when calling apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet.');
            }
            const localVarPath = `/api/v{version}/Timesheet/getLastByEmployeeId/{idEmployee}`
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetIdDelete: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionTimesheetIdDelete.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetIdDelete.');
            }
            const localVarPath = `/api/v{version}/Timesheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetIdGet: async (id: number, version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiVversionTimesheetIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetIdGet.');
            }
            const localVarPath = `/api/v{version}/Timesheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Timesheet.
         * @param {string} version 
         * @param {CreateTimesheetCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetPost: async (version: string, body?: CreateTimesheetCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetPost.');
            }
            const localVarPath = `/api/v{version}/Timesheet`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Timesheet
         * @param {string} version 
         * @param {UpdateTimesheetCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetPut: async (version: string, body?: UpdateTimesheetCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionTimesheetPut.');
            }
            const localVarPath = `/api/v{version}/Timesheet`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimesheetApi - functional programming interface
 * @export
 */
export const TimesheetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of Timesheets by Date Range
         * @param {number} idEmployee 
         * @param {Date} fromDate 
         * @param {Date} toDate 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee: number, fromDate: Date, toDate: Date, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, fromDate, toDate, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of Timesheets by Job Id
         * @param {number} idJob 
         * @param {string} version 
         * @param {number} [offset] 
         * @param {number} [take] Limits the number of records returned. Default:              All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetByJobIdJobGet(idJob: number, version: string, offset?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TimesheetListResponseResponse>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetGetByJobIdJobGet(idJob, version, offset, take, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets last Timesheet by Employee id
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TimesheetByIdResponseResponse>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<StatusCodeResult>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetIdDelete(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a Timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TimesheetByIdResponseResponse>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetIdGet(id, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a New Timesheet.
         * @param {string} version 
         * @param {CreateTimesheetCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetPost(version: string, body?: CreateTimesheetCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TimesheetResponse>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetPost(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an existing Timesheet
         * @param {string} version 
         * @param {UpdateTimesheetCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetPut(version: string, body?: UpdateTimesheetCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TimesheetResponse>>> {
            const localVarAxiosArgs = await TimesheetApiAxiosParamCreator(configuration).apiVversionTimesheetPut(version, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TimesheetApi - factory interface
 * @export
 */
export const TimesheetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets a list of Timesheets by Date Range
         * @param {number} idEmployee 
         * @param {Date} fromDate 
         * @param {Date} toDate 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee: number, fromDate: Date, toDate: Date, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, fromDate, toDate, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of Timesheets by Job Id
         * @param {number} idJob 
         * @param {string} version 
         * @param {number} [offset] 
         * @param {number} [take] Limits the number of records returned. Default:              All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetByJobIdJobGet(idJob: number, version: string, offset?: number, take?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<TimesheetListResponseResponse>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetGetByJobIdJobGet(idJob, version, offset, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets last Timesheet by Employee id
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TimesheetByIdResponseResponse>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetIdDelete(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<StatusCodeResult>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetIdGet(id: number, version: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TimesheetByIdResponseResponse>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Timesheet.
         * @param {string} version 
         * @param {CreateTimesheetCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetPost(version: string, body?: CreateTimesheetCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<TimesheetResponse>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetPost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Timesheet
         * @param {string} version 
         * @param {UpdateTimesheetCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetPut(version: string, body?: UpdateTimesheetCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<TimesheetResponse>> {
            return TimesheetApiFp(configuration).apiVversionTimesheetPut(version, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimesheetApi - object-oriented interface
 * @export
 * @class TimesheetApi
 * @extends {BaseAPI}
 */
export class TimesheetApi extends BaseAPI {
    /**
     * 
     * @summary Gets a list of Timesheets by Date Range
     * @param {number} idEmployee 
     * @param {Date} fromDate 
     * @param {Date} toDate 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee: number, fromDate: Date, toDate: Date, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, fromDate, toDate, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a list of Timesheets by Job Id
     * @param {number} idJob 
     * @param {string} version 
     * @param {number} [offset] 
     * @param {number} [take] Limits the number of records returned. Default:              All
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetGetByJobIdJobGet(idJob: number, version: string, offset?: number, take?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<TimesheetListResponseResponse>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetGetByJobIdJobGet(idJob, version, offset, take, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets last Timesheet by Employee id
     * @param {number} idEmployee 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TimesheetByIdResponseResponse>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes a timesheet by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetIdDelete(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<StatusCodeResult>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a Timesheet by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetIdGet(id: number, version: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TimesheetByIdResponseResponse>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Creates a New Timesheet.
     * @param {string} version 
     * @param {CreateTimesheetCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetPost(version: string, body?: CreateTimesheetCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<TimesheetResponse>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetPost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an existing Timesheet
     * @param {string} version 
     * @param {UpdateTimesheetCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public async apiVversionTimesheetPut(version: string, body?: UpdateTimesheetCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<TimesheetResponse>> {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetPut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
}
