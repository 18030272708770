import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/global/Global.scss'

import intl from "react-intl-universal";

const local = navigator.language;

const languageStorage = localStorage.language;

let language;
if (!languageStorage) {
  if (local === 'es-ES') {
    language = 'es';
  } else {
    language = 'en';
  }
} else {
  language = languageStorage;
};

const locales = {
  en: require('./locales/en.json'),
  es: require('./locales/es.json')
};

intl.init({
  locales,
  currentLocale: language
});

ReactDOM.render(
  <React.StrictMode>
  
      <App />
  
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
