import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';
import '../../scss/Time Off/TimeOffRow.scss';
import Chip from '@mui/material/Chip';
import { HiOutlineCheckCircle, HiOutlineXCircle, HiDocument, HiEye } from 'react-icons/hi';
import ModalTimeOff from './ModalTimeOff';

const TimeOffRow = ({ className, id, employee, status, request, date }) => {

    const [isTimeOffRejectOpen, setIsTimeOffRejectOpen] = useState(false);

    const navigate = useNavigate();

    const closeTimeOffReject = () => setIsTimeOffRejectOpen(false);

    const color = status === "Pending" || status === "Pendiente" ? "warning" : status === "Approved" || status === "Aprobado" ? "success" : status === "Observed" || status === "Observado" ? "default" : status === "Open" || status === "Abierto" ? "primary" : status === "Reopen" || status === "Reabrir" ? "secondary" : "error";

    const handleTimeOffRejectOpen = () => {
        setIsTimeOffRejectOpen(true);
    };

    const handleTimeOffDetail = () => {
        navigate(`/timeOffRequest/detail/${id}`);
    };

    return (
        <tr className={className}>
            <td>{employee}</td>
            <td>
                <Chip label={status} color={color} className={color === "default" ? 'observed' : ''}/>
            </td>
            <td>{request}</td>
            <td>{date}</td>
            <td className='text-center'>
                <a title={intl.get('timeOffRow.confirmButton.title')} className='td-icon' role="button">
                    <HiOutlineCheckCircle className='iconSaveTimeOff me-1'/>
                </a>
                <a title={intl.get('timeOffRow.refuseButton.title')} className='td-icon' role="button" onClick={handleTimeOffRejectOpen} >
                    <HiOutlineXCircle className='iconCancelTimeOff'/>
                </a>
                <ModalTimeOff isOpen={isTimeOffRejectOpen} closeModal={closeTimeOffReject} />
            </td>
            <td className='text-center'>
                <a className='td-icon mx-1' title={intl.get('timeOffRow.documentButton.title')}>
                    <HiDocument/>
                </a>
                <a className='td-icon mx-1' title={intl.get('timeOffRow.detailButton.title')}>
                    <HiEye onClick={handleTimeOffDetail}/>
                </a>
            </td>
        </tr>
    )
}

export default TimeOffRow;