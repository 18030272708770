import React from "react";
import TimeOffReject from "./TimeOffReject";
import Modal, { ModalSizes } from "../Modal";

const ModalTimeOff = ({ isOpen, closeModal }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <TimeOffReject closeModal={closeModal}/>
        </Modal>
    )
}

export default ModalTimeOff;