import { getUserEntity } from 'common/UserEntityProvider';

export const hasPermission = ({ scopes }) => {

  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  const permissions = getUserEntity()?.scopes.map(x => x.scope);


  return permissions?.some(
    (permission) => scopesMap[permission]
  );
};export const hasRole = ({ userRoles, roles }) => {
  const rolesMap = {};
  roles.forEach((role) => {
    rolesMap[role] = true;
  });

  return userRoles?.some((role) => rolesMap[role]);
};

