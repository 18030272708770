import React, { useState, ChangeEventHandler, useEffect, FC } from 'react';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import 'scss/Reports/ReportsComponent.scss';
import './ReportsFilterComponent';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { ReportsFilterComponent, ReportsFilterComponentProps } from './ReportsFilterComponent';
import { TimeSheetDetailedReport } from '../../axiosApi/models';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { HiChevronUp, HiChevronDown, HiOutlineCash } from 'react-icons/hi'
import { SCOPES } from '../../common/permissions';
import { PermissionsGate } from '../PermissionsGate';
import { styled } from '@mui/material/styles';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Colors } from 'chart.js';
import intl from 'react-intl-universal';

interface Task {
    idTask: number;
    taskDescription: string;
    time: number;
    idEmployee: number;
    employeeName: string;
    date: Date
}

interface Job {
    idJob: number;
    jobDescription: string;
    tasks: Task[];
    employees: Employee[];
    billableHours: number;
    totalHours: number;
    nonBillableHours: number;
    billingAmount: number;
}

interface Client {
    idClient: number;
    clientName: string;
    projects: Project[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    billingAmount: number;
}

interface Project {
    idProject: number;
    projectName: string;
    jobs: Job[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    employees: Employee[];
    billingAmount: number;
}

interface Employee {
    idEmployee: number;
    employeeName: string;
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    monthlyRate: number;
    hourlyRate: number;
    billingAmount: number;
}

type ProjectBudgetStatusReportProps = {
    projectId: string | null,
    startDate: Date,
    budget:number
};

export const ProjectBudgetStatusReport: FC<ProjectBudgetStatusReportProps> = ({projectId, startDate, budget}) => {

    const api: Api = useApi();
    const toast = useToastMessageQueue();
    const [results, setResults] = React.useState<TimeSheetDetailedReport[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [pieData, setPieData] = React.useState<any>(null);

    ChartJS.register(ArcElement, Tooltip, Legend, Colors);
    useEffect(() => {
        fetchData(startDate, projectId.toString()).then(()=>{
            // Actualiza el título del documento usando la API del navegador
            const clientJobTaskTree = createClientProjectJobTaskTree(results);
            setClients(clientJobTaskTree);
        });
    }, [projectId, startDate]);

    useEffect(() => {
        
        const clientJobTaskTree = createClientProjectJobTaskTree(results);
        setClients(clientJobTaskTree);
    
    }, [results]);


    const fetchData = async (startDate:Date, projectId: string) => {
        
        const response = await api.billingReportsApi.apiVversionBillingDetailedReportAllGet("1",
        startDate,  new Date(), projectId).then((response) => {
                if (response.data) {
                    setResults(response?.data?.data);

                };
            }).catch((error) => {
                if (error.response) {
                    console.log("Data :", error.response.data);
                    console.log("Status :" + error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toast.error({ header: intl.get('reports.toast.error.fetchData'), body: error.message });
            });
    }

    function createClientProjectJobTaskTree(timesheetData: TimeSheetDetailedReport[]): Client[] {
        const clients: Client[] = [];
        console.log({ timesheetData });
        if (timesheetData) {
            for (const entry of timesheetData) {
            let client = clients.find((c) => c.idClient === entry.idClient);

            if (!client) {
                client = {
                    idClient: entry.idClient,
                    clientName: entry.clientName,
                    projects: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                };
                clients.push(client);
            }
            else {
                client.billableHours += entry.billable ? entry.time : 0;
                client.nonBillableHours += entry.billable ? 0 : entry.time;
                client.totalHours += entry.time;
                client.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0);
            }


            let project = client.projects.find((p) => p.idProject === entry.idProject);

            if (!project) {
                project = {
                    idProject: entry.idProject,
                    projectName: entry.projectName,
                    jobs: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    employees: [],
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)

                };
                client.projects.push(project);
            }
            else {
                project.billableHours += entry.billable ? entry.time : 0;
                project.nonBillableHours += entry.billable ? 0 : entry.time;
                project.totalHours += entry.time;
                project.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0);
            }

            let job = project.jobs.find((j) => j.idJob === entry.idJob);

            if (!job) {
                job = {
                    idJob: entry.idJob,
                    jobDescription: entry.jobDescription,
                    tasks: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: !entry.billable ? entry.time : 0,
                    totalHours: entry.time,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0),
                    employees: []
                };
                project.jobs.push(job);
            }
            else {
                job.billableHours += entry.billable ? entry.time : 0;
                job.nonBillableHours += entry.billable ? 0 : entry.time;
                job.totalHours += entry.time;
                job.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0);
            }


            const task: Task = {
                idTask: entry.idTimesheet,
                taskDescription: entry.taskDescription,
                time: entry.time,
                idEmployee: entry.idEmployee,
                employeeName: entry.employeeName,
                date: entry.date
            };
            job.tasks.push(task);

            let employee = job.employees.find((j) => j.idEmployee === entry.idEmployee);
            if (!employee) {
                employee = {
                    idEmployee: entry.idEmployee,
                    employeeName: entry.employeeName,
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    hourlyRate: entry.hourlyRate,
                    monthlyRate: entry.monthlyRate,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                }
                job.employees.push(employee);
            }
            else {
                employee.billableHours += entry.billable ? entry.time : 0;
                employee.nonBillableHours += entry.billable ? 0 : entry.time;
                employee.totalHours += entry.time;
                employee.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? (entry.time * entry.hourlyRate) / 60 : 0);
            }

            //Pie Data
            const data = {
                labels: ["Remaining budget", "Overrun","Used budget"],
                datasets: [{
                    label: 'Budget ',
                    data: [Math.max(budget-client.billingAmount,0), Math.min(budget-client.billingAmount,0), client.billingAmount],

                    borderWidth: 0, 
                    hoverOffset: 6
                }]

            };

            setPieData(data);

            }
        }
        return clients;
    }

    return (
        
        <PermissionsGate viewScopes={[SCOPES['reports.billing.read']]} editScopes={[SCOPES['reports.billing.read']]} viewRoles={[]} editRoles={[]}  >
        {(budget > 0 )&& <>
            <div className='card mt-4'>
                <div className='container card-header'>
                    <h4 className="title">Performance</h4>
                </div>
                <div className='container card-body'>
                    <div className=' card '>
                        <div className="col-md-4 col-sm-12">{pieData && <Pie data={pieData} />}</div>
                    </div>
                   {/* <div className=' card '>
                        <div className="col-md-4 col-sm-12">{barData && <Bar data={barData} />}</div>
    </div> */}
                    
                </div>
            </div>
            </>}
        </PermissionsGate>
    
    )
}




