import React from 'react';
import RoleRow from './RoleRow';
import intl from 'react-intl-universal';

const RoleList = ({ searchResult, setSearchResult, setLoading }) => {

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' scope="col">{intl.get('roleList.table.thead.name')}</th>
                        <th role='button' scope="col">{intl.get('roleList.table.thead.description')}</th>
                        <th role='button' scope="col">{intl.get('roleList.table.thead.users')}</th>
                        <th role='button' scope="col">{intl.get('roleList.table.thead.status')}</th>
                        <th role='button' scope="col" style={{ textAlign: 'center' }}>{intl.get('roleList.table.thead.actions')}</th>
                    </tr>
                </thead>
                {
                    searchResult.queryResult === null ? 
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={6} className=' mb-0'>{intl.get('roleList.notFound')}</td>
                        </tr>
                    </tbody> :
                    <tbody>
                        {
                            searchResult.queryResult?.map((r,i) => (
                                <RoleRow 
                                    className={i%2>0?'':'odd'}
                                    key={r.id} 
                                    id={r.id} 
                                    name={r.name} 
                                    description={r.description}
                                    users={r.users}
                                    status={r.active}
                                    searchResult={searchResult}
                                    setSearchResult={setSearchResult}
                                    setLoading={setLoading}
                                />
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default RoleList;