import React, { useState, useEffect } from 'react';
import UserNavbar from './UserNavbar';
import UserPagination from './UserPagination';
import UserList from './UserList';
import Spinner from '../Spinner';
import '../../scss/User/Users.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import {PermissionsGate } from '../PermissionsGate';
import { SCOPES, ROLES } from "../../common/permissions"; 
import { User } from '../../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import ItemsSelect from '../ItemsSelect';

export type UsersInput = {
	queryTerm?: string | null,
	sortValue?: string | null,
	orderValue?: string | null,
	filterValue?: null,
	currentPage?: number,
	clicked?: boolean
};

export type SearchResult = {
	totalRecords: number | null,
	users: User[]
};

const Users = () => {

    const [input, setInput] = useState<UsersInput | null>({
		queryTerm: "",
		sortValue: "Email",
		orderValue: "asc",
		filterValue: null,
		currentPage: 1,
		clicked: false,
	});

    const api: Api = useApi();

	const [searchResult, setSearchResult] = useState<SearchResult | null>({
		totalRecords: null,
		users: []
	});

    const [loading, setLoading] = useState<boolean>(false);

	const [pageCount, setpageCount] = useState<number>(0);

	const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageUsers'), 10) || 10;
	});

	useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageUsers'), 10) || 10);
	}, []);

	const toast = useToastMessageQueue();

    const fetchUsers = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
		setLoading(true);
		const response = await api.userApi.apiVversionUserSearchGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
			if(response.data.data === null) {
				setSearchResult({
					totalRecords: null,
					users: null
				});
			} else {
				setSearchResult({
					totalRecords: response.data?.data?.totalRecords,
					users: response.data?.data?.queryResult
				});
			};
			setLoading(false);
			console.log("Success");
		}).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('users.toast.error.fetchUsers'), body: error.message });
            setLoading(false);
		});
	};

    useEffect(() => {
        fetchUsers();
    }, [input, limit]);

    const onSearch = (queryString) => {
		setInput({ ...input, queryTerm: queryString, currentPage: 1 });
	};

    const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    return (
        <div className="container">
			<div className='card mt-4'>
				<div className="card-header">
					<h2 className="title">{intl.get('users.header')}</h2>
				</div>
				<div className='card-body'>
                    <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.edit']]} viewRoles={null} editRoles={null} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
						<div className='row'>
							<div className='' id={`${input.clicked ? 'column-2' : ''}`} >
								<UserNavbar onSearch={onSearch}/>
								{
									loading === true ?
									<Spinner /> :
									<>
										<UserList searchResult={searchResult} input={input} setInput={setInput} />
										<div className="pagination row w-100">
											<div className="col-10 col-xl-11 d-flex justify-content-center">
												<UserPagination 
													handlePageClick={handlePageClick} 
													limit={limit} 
													searchResult={searchResult} 
													nextPage={nextPage} 
													prevPage={prevPage} 
													input={input} 
													pageCount={pageCount} 
													setpageCount={setpageCount} 
													initialPage={initialPage} 
													finalPage={finalPage}
												/>
											</div>
											<div className="col-2 col-xl-1 d-flex justify-content-end">
												<ItemsSelect 
													input={input}
													setInput={setInput}
													limit={limit} 
													setLimit={setLimit} 
													storageKey="itemsPerPageUsers"
												/>
											</div>
										</div>
									</>
								}
							</div>
						</div>
                	</PermissionsGate>
				</div>
			</div>
		</div>
    )
}

export default Users;