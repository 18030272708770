import React from "react";
import TimesheetLog from "./TimesheetLog";
import Modal, { ModalSizes } from "../Modal";

export default function TimesheetLogModal({ isOpen, closeModal, projects, handleTimesheetSave, timesheetEditProps, loading, timesheetRows, setTimesheetRows }) {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <TimesheetLog closeModal={closeModal} timesheetEditProps={timesheetEditProps} projects={projects} handleTimesheetSave={handleTimesheetSave} loading={loading} timesheetRows={timesheetRows} setTimesheetRows={setTimesheetRows}/>
        </Modal>
    )
}