import React from "react";
import Modal, { ModalSizes } from "../Modal";
import TaskJob from "./TaskJob";

type TaskJobModalProps = {
    isOpen: boolean,
    closeModal: Function,
    idProject: number,
    idJob: number,
    JobName: string,
    ProjectName: string,
    ClientName: string,
    isJiraReady: boolean
}

export default function TaskJobModal({ isOpen, closeModal, idProject, idJob, JobName, ProjectName, ClientName, isJiraReady }: TaskJobModalProps) {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.medium} height="auto">
            <TaskJob idProject={idProject} idJob={idJob} JobName={JobName} ProjectName={ProjectName} ClientName={ClientName} isJiraReady={isJiraReady} closeModal={closeModal} ></TaskJob>
        </Modal>
    )
}