import { AxiosRequestConfig } from 'axios';
import ClientSelect from 'components/Project/ClientSelect';
import { AddressApiAxiosParamCreator } from 'axiosApi/apis/address-api';
import Api from '../../axiosApi/api';
import { LoginApi, LoginApiFactory } from 'axiosApi/apis/login-api';
import { Settings } from 'common/settings';




const userPoolId = Settings.getInstance().USER_POOL_ID;
const clientId = Settings.getInstance().CLIENT_ID;
const LoginDomain = Settings.getInstance().LOGIN_DOMAIN;
const RedirectUrl = Settings.getInstance().REDIRECT_URL;
const ResponseType = Settings.getInstance().RESPONSE_TYPE;
console.log(`userpool id=${userPoolId}`)
console.log(`client id=${clientId}`)


const loginUrl = `${LoginDomain}/login?response_type=${ResponseType}&client_id=${clientId}&redirect_uri=${RedirectUrl}&state=STATE&scope=openid+email`;

//for using fedeated / hostedUI
export async function signIn() {
    window.location.href = loginUrl;
}

export async function getTokens(authCode, options: AxiosRequestConfig = {}):Promise<any> {
    //esto está mal. Aca lo que tenemos que hacer es llamar a un endpoint en una lambda nuestra, donde este guardado el clientSecret por configuracion (asi es secreto y no lo tenemos en el FE)
    //solo pasamos el authorization code, y este nos devuelve el accessToken. 
    const api = new LoginApi({basePath:  Settings.getInstance().BASE_API_URL_EMPLOYEE});
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    let baseOptions;
    const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions};
    const localVarHeaderParameter = { Authorization: `Basic ${authCode}` } as any;
    localVarHeaderParameter['Content-Type'] = 'application/json';

    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

    return api.apiVversionLoginPost("1", authCode, Settings.getInstance().REDIRECT_URL, localVarRequestOptions );
}

export async function refreshTokens(refreshToken, options: AxiosRequestConfig = {}):Promise<any> {

    //esto está mal. Aca lo que tenemos que hacer es llamar a un endpoint en una lambda nuestra, donde este guardado el clientSecret por configuracion (asi es secreto y no lo tenemos en el FE)
    //solo pasamos el authorization code, y este nos devuelve el accessToken. 
    const api = new LoginApi({basePath:  Settings.getInstance().BASE_API_URL_EMPLOYEE});
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    let baseOptions;
    const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions};
    const localVarHeaderParameter = {} as any;
    localVarHeaderParameter['Content-Type'] = 'application/json';

    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

    return api.apiVversionLoginRefreshPost("1", refreshToken, localVarRequestOptions );

}
    
export function signOut() {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('UserEntity');
}